// 修改对账单
<template>
  <div class="addBalanceAccount">
    <el-form ref="ruleForm" :model="balanceAccountData" label-width="122px" :rules="rules">
      <div class="addBalanceAccountContent">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/balanceAccount' }">对账单</el-breadcrumb-item>
          <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">修改对账单</el-breadcrumb-item>
        </el-breadcrumb>
        <nav>
          <h5>修改对账单</h5>
          <el-form-item label="供应商名称:" prop="sellerId">
            <el-select @change="chooseSell" v-model="balanceAccountData.sellerId" placeholder="请选择供应商" size="mini" filterable>
              <el-option v-for="(item, index) in sellList" :key="index" :label="item.name" :value="item.sellerId" :value-key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间区间:" prop="startTime">
            <!-- 审核日期 -->
            <div class="block">
              <el-date-picker
                v-model="chooseTime"
                @change="getTimess"
                type="daterange"
                range-separator="至"
                start-placeholder="创建日期区间"
                end-placeholder="创建日期区间"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>
        </nav>
        <main>
          <h5>对账单明细</h5>
          <el-table ref="tableHeight" max-height="241" :data="balanceAccountSonList" row-key="index" style="width: 100%;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineList'}" :show-overflow-tooltip="true">
            <el-table-column type="test" align="center" width="55">
              <template slot="header" slot-scope="scope">
                <el-checkbox v-model="chooseAllList" @change="handleChooseAllList"></el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-if="typeof(scope.row.choose) === 'boolean'" v-model="scope.row.choose" @change="handleChoosePartList"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="orderNum" label="采购单号" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="warrantCount" label="入库数量" min-wid th="80px"  align="center">
              <template slot-scope="scope">
                <!-- takeDeliveryNumber 已收货数量 warrantCount 入库数量 -->
                <span v-if="scope.row.detailType == 0">{{scope.row.takeDeliveryNumber}}</span>
                <span v-else-if="scope.row.detailType == 1">{{scope.row.warrantCount}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="returnCount" label="退货数量" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="单价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="totalAmount" label="产品总价" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="yfkAmount" label="预付款金额" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="kkAmount" label="扣款金额" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="resAmount" label="应付金额(不含税)" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="taxRate" label="税率" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.taxRate}}%</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="resAmount" label="应付金额(含税)" min-width="80px" align="center">
              <template slot-scope="scope">
                <span>{{(scope.row.taxAmount).toFixed(2)}}</span>
              </template>
            </el-table-column>
          </el-table>
        </main>
        <main>
          <h5>其他扣款单</h5>
          <el-table @select="handleSelectionChange" @select-all="handleSelectionChange" ref="tableHeight2" max-height="245" :data="chargeList" style="width: 100%;">
            <el-table-column  type="selection" width="45" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="number" label="扣款单号" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="name" label="扣款名称" min-width="80px" align="center"></el-table-column>
            <el-table-column show-overflow-tooltip prop="remark" label="扣款理由" min-width="280px"  align="left">
              <template slot-scope="scope">
                <div>
                  <span style="cursor: pointer; color: rgba(18, 62, 131, 1);">{{scope.row.remark}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="扣款金额" min-width="80px" align="center"></el-table-column>
          </el-table>
        </main>
      </div>
      <footer>
        <div class="footerBox">
          <el-form-item label="总金额(不含税):">
            <el-input v-model="showPrice.amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="总金额(含税):">
            <el-input v-model="showPrice.taxAmount" disabled></el-input>
          </el-form-item>
        </div>
        <el-button class="lightDeepStyle" @click="addBalanceAccount()">提交</el-button>
        <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
      </footer>
    </el-form>
  </div>
</template>
<script>
import { sellerListApi, balanceAccountSonListApi, chargeBackListApi, editBalanceAccountApi, balanceAccountDetailApi, moduleApi } from '@/api'
export default {
  data() {
    return {
      // 控制对账单明细是否全部勾选
      chooseAllList: false,
      // 供应商列表
      sellList: [],
      chooseTime: [],
      // 修改对账单
      balanceAccountData: {
        id: '',
        sellerId: '',
        dids: [],
        orderList: [],
        startTime: '',
        endTime: ''
      },
      // 展示的总金额
      showPrice: {
        amount: (0).toFixed(2),
        taxAmount: (0).toFixed(2),
        // 总预付款
        yfkAmount: (0).toFixed(2),
        tax: ''
      },
      // 对账单明细列表
      balanceAccountSonList: [],
      // 扣款单列表
      chargeList: [],
      // 勾选的扣款列表
      chooseChargeList: [],
      // 校验
      rules: {
        sellerId: [
          { required: true, message: '请选择供应商!', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '请选择时间区间!', trigger: 'blur' }
        ]
      },
      // 用来判断对账单明细中的数量显示的是收货数量还是入库数量
      detailType: 0
    }
  },
  created() {
    this.balanceAccountData.id = JSON.parse(this.$route.query.result)
    this.getModuleList()
    this.sellerList().then(() => {
      this.getBalanceAccountDetail().then(() => {
        this.getBalanceAccountSonList().then(() => {
          this.countPrice()
        })
        this.getChargeBackList()
      })
    })
  },
  mounted() {
  },
  methods: {
    // 获取配置模块
    async getModuleList() {
      const { data: res } = await moduleApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      // 0.收货单 1.入库单
      this.detailType = res.data.data[0].state
    },
    // 获取对账单详情
    async getBalanceAccountDetail() {
      const { data: res } = await balanceAccountDetailApi(this.balanceAccountData.id);
      if (res.code !== 0) return this.$message.error(res.msg)
      let newBalanceAccountDetail = res.data.detail
      // 给数据添加唯一值index,用于展开
      if (newBalanceAccountDetail.orderList.length !== 0) {
        newBalanceAccountDetail.orderList.forEach((item, index) => {
          item.choose = true
          item.index = index + ''
          if (item.lineList.length !== 0) {
            item.lineList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.balanceAccountDetail = newBalanceAccountDetail
      // 让全选勾上
      this.chooseAllList = true
      // 将详情的数据赋值到修改参数中
      this.balanceAccountData = {
        id: this.balanceAccountDetail.orderDetail.id,
        sellerId: this.balanceAccountDetail.orderDetail.sellerId,
        dids: [],
        orderList: [],
        startTime: this.balanceAccountDetail.orderDetail.startTime,
        endTime: this.balanceAccountDetail.orderDetail.endTime
      }
      this.chooseTime = [Date.parse(new Date(this.balanceAccountDetail.orderDetail.startTime)), Date.parse(new Date(this.balanceAccountDetail.orderDetail.endTime))]
      this.balanceAccountSonList = this.balanceAccountDetail.orderList
      this.chargeList = this.balanceAccountDetail.dtnList
      this.$nextTick(() => {
        this.chargeList.forEach(row => {
          this.$refs.tableHeight2.toggleRowSelection(row,true);
        })
        this.chooseChargeList = this.chargeList
        let arr = []
        this.chargeList.forEach(item => {
          arr.push(item.id)
        })
        this.balanceAccountData.dids = arr
        this.countPrice()
      })
    },
    // 计算对账单金额
    countPrice() {
      // 总金额(不含税) = 应付金额 - 扣款总额总和
      // 总金额(含税) = (应付金额 - 扣款总额总和 + 预付款金额) * (1+税率) - 预付款金额
      this.showPrice.amount = 0
      this.showPrice.yfkAmount = 0
      if (this.balanceAccountSonList.length !== 0) {
        this.balanceAccountSonList.forEach((item, index) => {
          if (item.choose === true) {
            this.showPrice.amount = (this.showPrice.amount - 0) + (item.resAmount - 0)
          }
        })
      }
      if (this.chooseChargeList.length !== 0) {
        // 减去全部的扣款金额
        this.chooseChargeList.forEach((item, index) => {
          this.showPrice.amount = this.showPrice.amount - item.price
        })
      }
      if (this.balanceAccountSonList.length !== 0 && this.balanceAccountSonList.length !== 0) {
        // 预付款总金额
        this.balanceAccountSonList.forEach((item, index) => {
          if (item.choose === true) {
            this.showPrice.yfkAmount = (this.showPrice.yfkAmount - 0) + item.yfkAmount
          }
        })
      }
      // 最后保留两位小数
      this.showPrice.amount = this.showPrice.amount.toFixed(2)
      this.showPrice.taxAmount = (((this.showPrice.amount - 0) + this.showPrice.yfkAmount) * (this.showPrice.tax + 1) - this.showPrice.yfkAmount).toFixed(2)
    },
    // 勾选其中的一个对账单明细列表
    handleChoosePartList(selection) {
      if (selection === true) {
        let isLast = true
        console.log(this.balanceAccountSonList)
        // 判断当前勾选的是否是最后一个待勾选项
        this.balanceAccountSonList.forEach((item, index) => {
          if (item.choose === false) {
            isLast = false
          }
        })
        if (isLast === true) {
          // 让全选框勾选上
          this.chooseAllList = true
        }
      } else {
        this.chooseAllList = false
      }
      this.countPrice()
    },
    // 勾选对账单明细全选时触发
    handleChooseAllList(selection) {
      if (this.balanceAccountSonList.length !== 0) {
        this.balanceAccountSonList.forEach((item, index) => {
          item.choose = selection
        })
        this.countPrice()
      }
    },
    // 手动勾选的参数(扣款单专用)
    handleSelectionChange(selection) {
      this.chooseChargeList = selection
      let arr = []
      selection.forEach(item => {
        arr.push(item.id)
      })
      this.balanceAccountData.dids = arr
      this.countPrice()
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 1, status: 3 })
      this.sellList = res.data.page.list
    },
    // 选择供应商
    chooseSell() {
      if (this.balanceAccountData.startTime !== '') {
        this.getBalanceAccountSonList()
        this.getChargeBackList()
      }
    },
    // 点击切换时间表
    getTimess(result) {
      console.log(result, 666)
      if (result === null) {
        this.balanceAccountData.startTime = ''
        this.balanceAccountData.endTime = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value);
          let getYear = timers.getFullYear();
          let nowMonth = timers.getMonth() + 1;
          let getDate = timers.getDate();
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.balanceAccountData.startTime = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.balanceAccountData.endTime = getYear + '-' + nowMonth + '-' + getDate + '23:59:59'
          }
        })
        if (this.balanceAccountData.sellerId !== '') {
          this.getBalanceAccountSonList()
          this.getChargeBackList()
        }
      }
    },
    // 获取对账单明细
    async getBalanceAccountSonList() {
      const { data: res } = await balanceAccountSonListApi({ id: '', sellerId: this.balanceAccountData.sellerId, startTime: this.balanceAccountData.startTime, endTime: this.balanceAccountData.endTime })
      let newBalanceAccountSonList = res.data.detail.list
      this.showPrice.tax = res.data.detail.tax * 0.01
      // 给数据添加唯一值index,用于展开 (同时添加是否勾选字段)
      this.chooseAllList = false
      if (newBalanceAccountSonList.length !== 0) {
        newBalanceAccountSonList.forEach((item, index) => {
          item.choose = false
          item.index = index + ''
          if (item.lineList.length !== 0) {
            item.lineList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
        newBalanceAccountSonList.forEach((item, index) => {
          this.balanceAccountSonList.push(item)
        })
      }
      
    },
    // 获取扣款列表
    async getChargeBackList() {
      // 扣款单参数(只要未结算的)
      let chargeBackData = {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        startTime: this.balanceAccountData.startTime,
        endTime: this.balanceAccountData.endTime,
        name: '',
        sellerName: '',
        sellerId: this.balanceAccountData.sellerId,
        status: 0,
        type: 0,
        auditStatus: 1
      }
      const { data: res } = await chargeBackListApi(chargeBackData)
      // 获取表单数据
      let newChargeList = res.data.page.list
      if (newChargeList.length !== 0) {
        newChargeList.forEach((item, index) => {
          this.chargeList.push(item)
        })
      }
    },
    // 页面跳转
    routerJump() {
      this.$router.back()
    },
    // 修改对账单
    addBalanceAccount() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          // 判断必须勾选一条对账单明细
          if (this.balanceAccountSonList.length === 0) return this.$message.error('请先勾选任意对账单明细后生成对账单!')
          // 获取勾选的对账单明细
          this.balanceAccountSonList.forEach((item, index) => {
            if (item.choose === true) {
              this.balanceAccountData.orderList.push(item)
            }
          })
          if (this.balanceAccountData.orderList.length === 0) return this.$message.error('请先勾选任意对账单明细后生成对账单!')
          const { data: res } = await editBalanceAccountApi(this.balanceAccountData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '修改成功!' })
          setTimeout(() => {
            this.$router.back()
          }, 300)
        } else {
          return false
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.addBalanceAccount {
  .addBalanceAccountContent {
    padding: 0 20px 20px;
    position: relative;
    box-sizing: border-box;
    height: calc(100vh - 188px);
    overflow-y: auto;
    .nowPage {
      /deep/ .el-breadcrumb__inner {
        color: rgba(18, 62, 131, 1);
      }
    }
    nav {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 20px;
      height: 166px;
      justify-content: space-between;
      box-sizing: border-box;
      background: #fff;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.80);
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      /deep/.el-form-item {
        margin: 20px 0 0 0;
        width: 100%;
        .el-form-item__error {
          padding: 5px 0 0 0;
        }
        .el-form-item__content {
          width: calc(100% - 132px);
          .el-input {
            width: 100%;
          }
          .el-select {
            width: 100%;
          }
          .el-cascader {
            width: 100%;
          }
          .el-date-editor {
            width: 100%;
          }
        }
      }
    }
    main {
      margin-top: 20px;
      padding: 20px;
      background: #fff;
      border-radius: 10px;
      overflow-y: auto;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 128px;
    width: calc(100% - 150px);
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
    z-index: 1;
    .footerBox {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      /deep/.el-form-item {
        position: relative;
        margin: 10px 0;
        width: 50%;
        .priceBox {
          position: absolute;
          right: 10px;
        }
        .el-form-item__error {
          padding: 5px 0 0 0;
        }
        .el-form-item__content {
          width: calc(100% - 132px);
          .el-input {
            width: 100%;
            .el-input__inner {
              background-color: rgba(0, 0, 0, 0.05);
              border: 1px solid transparent;
              font-size: 24px;
              color: rgba(0, 0, 0, 0.80);
            }
          }
        }
      }
    }
  }
}
</style>