// 产品管理详情
<template>
  <div class="matterDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/matterManage' }">产品管理</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">产品详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="editBox" style="float: right;">
      <el-button class="lightDeepStyle" @click="routerJump(1)"><i class="el-icon-arrow-left"></i>返回</el-button>
      <el-button class="lightDeepStyle" @click="routerJump(2)"><i class="el-icon-edit-outline"></i>编辑</el-button>
    </div>
    <div class="matterDetailContent">
      <header>
        <img v-if="matterData.newPic === ''" src="https://newsrm-user.oss-cn-hangzhou.aliyuncs.com/img/noImg.png">
        <img v-else :src="matterData.newPic">
        <div>
          <h5>{{matterData.name}}</h5>
          <p>创建时间: <span>{{matterData.createTime}}（{{matterData.userName}}）</span> | 更新时间: <span>{{matterData.updateTime}}（{{matterData.userName}}）</span></p>
        </div>
      </header>
      <main>
        <h6>产品信息</h6>
        <ul>
          <li>产品分类: <span>{{matterData.typeName}}</span></li>
          <li>产品类型: <span>{{matterData.productType === 0 ? '成品' : (matterData.productType === 1 ? '半成品' : (matterData.productType === 2 ? '物料成品' : '服务'))}}</span></li>
          <li>材料: <span>{{matterData.newMaterials == '' || matterData.newMaterials == null ? '暂未填写' : matterData.newMaterials}}</span></li>
          <li>单位: <span>{{matterData.unit == '' || matterData.unit == null ? '暂未填写' : matterData.unit}}</span></li>
          <li>规格: <span>{{matterData.specs == '' || matterData.specs == null ? '暂未填写' : matterData.specs}}</span></li>
          <li>颜色: <span>{{matterData.newColor == '' || matterData.newColour == null ? '暂未填写' : matterData.newColour}}</span></li>
        </ul>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 修改物料数据
      matterData: {
        id: '',
        name: '',
        typeId: '',
        productType: '',
        warehouseIds: '',
        unitUserId: '',
        specs: '',
        newColor: '',
        newMaterials: '',
        newPic: ''
      }
    }
  },
  created() {
    this.matterData = JSON.parse(this.$route.query.result)
    console.log(this.matterData, '6666')
    // this.matterData = {
    //   id: newData.id,
    //   name: newData.name,
    //   typeId: newData.typeId,
    //   productType: newData.productType,
    //   unitUserId: newData.unitUserId,
    //   specs: newData.specs,
    //   newColor: newData.newColor,
    //   newMaterials: newData.newMaterials,
    //   newPic: newData.newPic
    // }
  },
  methods: {
    routerJump(val) {
      if (val === 1) {
        this.$router.back()
      } else {
        this.$router.push({ path: "/editMatter", query: { result: JSON.stringify(this.matterData) }})
      }
    }
  }
};
</script>
<style lang="less" scoped>
.matterDetail {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .editBox {
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      margin-right: 5px;
    }
  }
  .matterDetailContent {
    margin-top: 40px;
    header {
      margin: 20px;
      padding: 20px;
      display: flex;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      img {
        margin-right: 20px;
        width: 120px;
        height: 120px;
        border: 10px;
      }
      div {
        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0,0,0,0.8);
          line-height: 19px;
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
    main {
      margin: 20px;
      padding: 20px;
      box-sizing: border-box;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 10px;
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          margin-top: 20px;
          width: 33.33%;
          color: rgba(0, 0, 0, 0.40);
          span {
            margin-left: 3px;
            color: rgba(0, 0, 0, 0.80);
          }
        }
      }
    }
  }
}
</style>