// 产品管理
<template>
  <div class="matterManage">
    <nav>
      <h5>产品管理</h5>
      <div>
        <el-button v-if="btnLimit.includes('新增产品')" class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增产品</el-button>
        <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
      </div>
    </nav>
    <section>
      <el-input class="sectionInp" v-model="matterManageData.name" placeholder="请输入产品名称" @input="getMatterManageList" prefix-icon="el-icon-search"></el-input>
      <el-cascader @change="handleChange" ref="refHand" :options="matterClassList" :props="defaultData" v-model="matterManageData.typeId" size="mini" filterable @visible-change="getMatterClass()" placeholder="请选择分类"></el-cascader>
      <el-select v-model="matterManageData.productType" placeholder="请选择产品类型" clearable size="mini" @change="getMatterManageList">
        <el-option label="成品" :value="0"></el-option>
        <el-option label="半成品" :value="1"></el-option>
        <el-option label="物料" :value="2"></el-option>
        <el-option label="服务" :value="3"></el-option>
      </el-select>
      <el-select v-model="matterManageData.status" placeholder="请选择状态" clearable size="mini" @change="getMatterManageList">
        <el-option label="启用" value="1"></el-option>
        <!-- <el-option label="锁定" value="2"></el-option> -->
        <el-option label="停用" value="3"></el-option>
      </el-select>
    </section>
    <main>
      <el-table ref="tableHeight" :height="tableHeight" :data="matterManageList" style="width: 100%;" @select="handleSelectionChange" @select-all="allChoose">
        <el-table-column  type="selection" width="45" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="code" label="产品编码" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span style="cursor: pointer; font-weight: 500" @click="routerJump(3, scope.row)">{{scope.row.code}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="newImg" label="产品图片" min-width="80px" align="center">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.newPic === '' ? 'https://newsrm-user.oss-cn-hangzhou.aliyuncs.com/img/noImg.png' : scope.row.newPic">
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="130px" align="center">
          <template slot-scope="scope">
            <div>
              <span @click="routerJump(3, scope.row)" style="color: rgba(18, 62, 131, 1); cursor: pointer; font-weight: 500">{{scope.row.name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="typeName" label="产品分类" min-width="100px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="产品类型" min-width="120px"  align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.productType === 0">成品</span>
              <span v-if="scope.row.productType === 1">半成品</span>
              <span v-if="scope.row.productType === 2">物料成品</span>
              <span v-if="scope.row.productType === 3">服务</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unit" label="单位" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.unit === '' || scope.row.unit === null ? '暂未填写' : scope.row.unit}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.specs === '' || scope.row.specs === null ? '暂未填写' : scope.row.specs}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="newColour" label="颜色" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.newColour === '' || scope.row.newColour === null ? '暂未填写' : scope.row.newColour}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="newMaterials" label="材料" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.newMaterials === '' || scope.row.newMaterials === null ? '暂未填写' : scope.row.newMaterials}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="warehouseName" label="仓库名称" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.warehouseName === '' || scope.row.warehouseName === null ? '暂无' : scope.row.warehouseName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.createTime === '' || scope.row.createTime === null ? '暂未填写' : (scope.row.createTime).substring(0, 10)}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="updateTime" label="更新时间" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <span>{{scope.row.updateTime === '' || scope.row.updateTime === null ? '暂未填写' : (scope.row.updateTime).substring(0, 10)}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="btnLimit.includes('启用停用')" show-overflow-tooltip prop="productUnit" label="状态" min-width="80px"  align="center">
          <template slot-scope="scope">
            <div>
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="3"
                active-color="rgba(18, 62, 131, 1)"
                inactive-color="#eee"
                @change="editMatterStatus(scope.row.id, scope.row.status)">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="操作" min-width="80px"  align="center">
          <template slot-scope="scope">
            <el-dropdown @command="chooseCommand($event, scope.row)">
              <span>
                <i style="transform: rotate(90deg)" class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdownUl">
                <el-dropdown-item v-if="btnLimit.includes('编辑产品')" command="1">
                  <i class="el-icon-edit-outline"></i><span>修改</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item command="2">
                  <i class="el-icon-delete"></i><span>删除</span>
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" class="emptyBg">
          <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
          <span style="display: block; margin-top: -14%">暂无数据</span>
        </div>
      </el-table>
      <el-pagination
        style="margin: 10px 0"
        layout="prev, pager, next"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        @size-change="handlesizeChange"
        :page-sizes="[50, 100, 500]"
        :page-size="matterManageData.size"
        :total="total">
      </el-pagination>
    </main>
  </div>
</template>
<script>
import Utils from '../util.js'
import {
  delMatterApi,
  matterListApi,
  matterExportApi,
  matterClassApi,
  editMatterStatusApi,
  matterUploadKeepApi,
  cpglExportApi
} from '@/api'
export default {
  name: 'matterManage',
  data() {
    return {
      // 物料管理列表
      matterManageList: [],
      // 传参参数
      matterManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 6,
        status: '',
        typeId: '',
        name: '',
        codeAndName: '',
        productType: ''
      },
      exportData: {
        describes: '',
        num: '',
        specs: '',
        typeName: '',
        name: '',
        groupId: '',
        typeId: '',
        warehouseId: '',
        status: ''
      },
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 物料分类列表
      matterClassList: [],
      isFirstEnter: false,
      // ----------------------------------------------------
      // 导入选择的类型
      uploadBusinessType: [],
      // 列表动态高度
      tableHeight: '',
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      companyId: window.$cookies.get('companyId'),
      // 系统参数数据(部分下拉选项数据)
      systemDisposeList: JSON.parse(
        window.sessionStorage.getItem('systemDispose')
      ),
      // 存储采购组列表
      purchaseList: [],
      // 级联选择器分类
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        // 不关联父子节点
        checkStrictly: true
      },
      // 保存勾选的row的值
      allRowId: [],
      // 计量单位列表
      unitUserList: [],
      // 上传时的请求头
      uploadHeaders: {
        token: window.$cookies.get('token')
      },
      // 控制批量导入弹出框
      dialogUpload: false,
      // 展示导入内容数据
      uploadData: [],
      // 控制显示新增还是修改
      controlMatterDialog: '1',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
    this.getMatterClass()
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    console.log(this.btnLimit, '~~~')
    this.getMatterManageList()
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 152
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - that.$refs.tableHeight.$el.offsetTop - 152
      })()
    }
  },
  activated() {
    if (this.$route.meta.isNeedRefresh || this.isFirstEnter) {
      this.getMatterManageList()
    }
    this.isFirstEnter = false
    this.$route.meta.isNeedRefresh = true
  },
  methods: {
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(2, result)
      } else {
        this.delMatter(result.id)
      }
    },
    // 删除产品
    delMatter(id) {
      this.$confirm('是否确认删除当前产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async() => {
        const { data: res } = await delMatterApi([id])
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '删除成功!' })
        this.getMatterManageList()
      })
    },
    // 修改物料状态
    async editMatterStatus(id, status) {
      console.log(id, '6666')
      if (status === 3) {
        this.$confirm('是否确定停用当前物料?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(async() => {
          const { data: res } = await editMatterStatusApi({ ids: [id], status: 3 })
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success({ duration: 1000, message: '停用成功!' })
          this.getMatterManageList()
        }).catch(() => {
          this.getMatterManageList()
        })
      } else {
        const { data: res } = await editMatterStatusApi({ ids: [id], status: 1 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success({ duration: 1000, message: '启用成功!' })
        this.getMatterManageList()
      }
    },
    // 获取物料分类
    async getMatterClass() {
      const { data: res } = await matterClassApi('')
      this.matterClassList = res.data.typeList
      this.matterClassList.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.childList.forEach((item2, index) => {
            if (item2.childList.length !== 0) {
              item2.newChildList = item2.childList
              item2.childList.forEach((item2, index) => {
                if (item3.childList.length !== 0) {
                  item3.newChildList = item3.childList
                }
              })
            }
          })
        }
      })
    },
    // 获取物料管理列表
    async getMatterManageList(reset) {
      if (reset !== 'reset') {
        this.matterManageData.page = 1
      }
      if (this.matterManageData.typeId === null) {
        this.matterManageData.typeId = ''
      }
      const { data: res } = await matterListApi(this.matterManageData)
      // 获取表单数据
      this.matterManageList = res.data.page.list
      // 展示当前页面
      this.currentPage = res.data.page.currPage
      // 展会页面总页数
      this.totalNum = res.data.page.totalPage
      // 展示总条数
      this.total = res.data.page.totalCount
      this.matterManageList.forEach(item => {
        if (
          item.businessType !== null &&
          typeof item.businessType === 'string'
        ) {
          item.businessType = item.businessType.split(',')
        } else if (item.businessType === null) {
          item.businessType = []
        }
      })
    },
    // 手动勾选的参数
    handleSelectionChange(selection, row) {
      let arr = []
      selection.forEach(item => {
        arr.push(item.id)
      })
      this.allRowId = arr
    },
    // 全选
    allChoose(selection) {
      let arr = []
      selection.forEach(item => {
        arr.push(item.id)
      })
      this.allRowId = arr
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/addMatter' })
      } else if (val === 2) {
        this.$router.push({ path: '/editMatter', query: { result: JSON.stringify(result) } })
      } else {
        this.$router.push({ path: '/matterDetail', query: { result: JSON.stringify(result) } })
      }
    },
    // 级联选择器改变触发 (物料分类的)
    handleChange(e) {
      if (e === null) {
        this.matterData.typeId = 0
      }
      // 实现级联选择器选中文字关闭级联选择框
      if (this.$refs.refHandles) {
        this.$refs.refHandles.dropDownVisible = false
      }
      if (this.$refs.refHandle) {
        this.$refs.refHandle.dropDownVisible = false
      }
      if (this.$refs.refHand) {
        this.$refs.refHand.dropDownVisible = false
      }
      this.getMatterManageList()
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.matterManageData.size = val
      this.matterManageData.page = 1
      this.getMatterManageList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.matterManageData.page = val
      this.currentPage = val
      this.getMatterManageList('reset')
    },
    // ---------------------------------------------------------
    // 物料导出
    async matterExport() {
      const { data: res } = await matterExportApi(this.matterManageData)
      console.log(res, '采购计划导出回调')
      this.getMatterManageList()
    },
    // 批量导入成功触发
    uploadSuccess(res, file) {
      this.dialogUpload = true
      this.uploadData = res.data.list
      this.$refs.purchaseUpload.clearFiles()
    },
    // 批量导入失败触发
    uploadError(err) {
      this.$message.error(err)
    },
    // 导入文件格式校验
    handleExceed() {
      this.$message.error('只能导入单个文件!')
    },
    // 取消导入
    cancelUpload() {
      this.dialogUpload = false
    },
    // 关闭弹框时触发
    handleClose() {
      this.$refs.purchaseUpload.clearFiles()
    },
    // 导入保存
    async purchasePlanUploadKeep() {
      if (this.uploadData.length === 0) { return this.$message.error('请添加数据后再保存!') }
      let controlImp = true
      this.uploadData.forEach(item => {
        if (item.success === false) {
          controlImp = false
          return false
        }
      })
      if (controlImp === false) return this.$message.error('校验未通过,请修正后重试!')
      const { data: res } = await matterUploadKeepApi(this.uploadData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '导入保存成功!' })
      this.dialogUpload = false
      this.getMatterManageList()
    },
    // 产品管理导出
    async purchaseReturnExport() {
      const { data: res } = await cpglExportApi(this.exportData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getMatterManageList()
    }
  }
}
</script>
<style scoped lang='less'>
.matterManage {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  nav {
    padding: 20px;
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0,0,0,0.8);
      line-height: 19px;
    }
    div {
      display: flex;
      justify-content: space-between;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  section {
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .el-select {
      margin: 0 20px 0 0;
    }
    .el-cascader {
      margin: 0 20px 0 0;
    }
    .block {
      margin-right: 20px;
    }
  }
  main {
    margin: 0 20px;
    .el-table .cell.el-tooltip {
      img {
        width: 60px;
        height: 60px;
        border-radius: 4px;
      }
    }
  }
}
</style>
