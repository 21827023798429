// 发布竞价
<template>
  <div class='addAskPrice'>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/competePricePurchase' }">竞价采购</el-breadcrumb-item>
      <el-breadcrumb-item class="nowPage" style="color: rgba(18, 62, 131, 1)">发布竞价</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addAskPriceContent">
      <el-form ref="ruleForm" :model="addData" label-width="117px" :rules="rules">
        <nav>
          <h5>竞价采购单</h5>
          <el-form-item label="标题" prop="name">
            <el-input v-model="addData.name" placeholder="竞价单标题" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </nav>
        <section> 
          <h5>产品明细</h5>
          <el-button class="lightDeepStyle addBtn" @click="openAddDialog()"><i class="el-icon-plus"></i>添加产品</el-button>
          <el-table ref="matterSearchRef" :data="addData.enquiryLineEntityList" style="width: 100%" min-height="100px">
            <el-table-column show-overflow-tooltip label="产品信息" min-width="240px" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.productCode + ' - ' + scope.row.productName + ' - ' + (scope.row.productSpecs === null ? '暂无' : scope.row.productSpecs) + ' - ' + (scope.row.productNewColour === null ? '暂无' : scope.row.productNewColour) + ' - ' + (scope.row.unitName === null ? '暂无' : scope.row.unitName)}}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="采购数量" min-width="80px" align="center">
              <template slot="header">
                <span><i style="color: red;">*</i>采购数量</span>
              </template>
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.number" @input="formatNum(scope.row.number, scope.$index)" oninput="if(value.length > 8) value=value.slice(0, 8)" placeholder="请输入"></el-input>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="要求交货时间" min-width="180px" align="center">
              <template slot="header">
                <span><i style="color: red;">*</i>要求交货时间</span>
              </template>
              <template slot-scope="scope">
                <el-date-picker :picker-options="pickerOptions" v-model="scope.row.arriveTime" type="date" placeholder="到货日期选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip min-width="150px" label="产品描述" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark" placeholder="请输入" oninput="if(value.length > 30) value=value.slice(0, 30)"></el-input>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="productCode" label="操作" width="204px" align="center">
              <template slot-scope="scope">
                <div>
                  <span @click="openUpload(scope.$index, scope.row)" style="margin-right: 20px; cursor: pointer;"><i class="el-icon-upload2"></i>上传附件</span>
                  <span @click="delMatter(scope.$index)" style="cursor: pointer;"><i class="el-icon-delete"></i>删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </section>
        <main>
          <h5>竞价要求</h5>
          <div class="mainContent">
            <el-form-item label="竞价开始日期: " prop="roundEntityList[0].beginTime" :rules="rules.beginTime">
              <el-date-picker @change="countData(1)" :picker-options="pickerOptions" v-model="addData.roundEntityList[0].beginTime" type="datetime" placeholder="请选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="第1轮报价截止日期: " label-width="150px" prop="roundEntityList[0].endTime" :rules="rules.endTime">
              <el-date-picker @change="countData(2)" :picker-options="pickerOptions" v-model="addData.roundEntityList[0].endTime" type="datetime" placeholder="请选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="第1轮竞价周期: " prop="days">
              <span v-if="addData.roundEntityList[0].beginTime == '' || addData.roundEntityList[0].endTime == ''">选择开始和截止日期后自动读取</span>
              <span v-else>{{addData.roundEntityList[0].days}}天 {{addData.roundEntityList[0].hours}}时 {{addData.roundEntityList[0].minutes}}分</span>
            </el-form-item>
            <el-form-item label="竞价轮数: " prop="numberRound">
              <el-select v-model="addData.numberRound" placeholder="选择轮数">
                <el-option label="一轮" :value="1"></el-option>
                <el-option label="两轮" :value="2"></el-option>
                <el-option label="三轮" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报价方式: " prop="offerType">
              <el-radio-group v-model="addData.offerType">
                <el-radio :label="1">单品报价</el-radio>
                <el-radio :label="2">整单报价</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="报价要求: " prop="offerAsk">
              <el-checkbox v-model="addData.offerAsk" :true-label="1" :false-label="2">报价含税</el-checkbox>
            </el-form-item>
          </div>
        </main>
        <main>
          <h5>{{'联系人&地址'}}</h5>
          <div class="mainContent">
            <el-form-item label="收货地址" prop="jiaofuId">
              <el-select v-model="addData.jiaofuId" placeholder="请选择收货地址" @change="chooseAddress()">
                <el-option v-for="(item, index) in addressList" :key="index" :label="item.province + item.city + item.district + item.area" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人" prop="peopleName">
              <el-input v-model="addData.peopleName" placeholder="联系人可选择地址读取"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机号" prop="peoplePhone">
              <el-input v-model="addData.peoplePhone" placeholder="联系人手机号可选择地址读取"></el-input>
            </el-form-item>
          </div>
        </main>
        <article>
          <h5>供应商要求</h5>
          <div class="articleContent">
            <el-form-item show-overflow-tooltip label="经营地址" min-width="100px" align="center" prop="provice">
              <el-cascader ref="cascaderRef" :options="provinceList" :props="defaultData" clearable v-model="addData.provice" @change="handleChange($event)" size="mini"></el-cascader>
            </el-form-item>
            <el-form-item label="支付方式" prop="payType">
              <el-radio-group v-model="addData.payType">
                <el-radio :label="1">现金</el-radio>
                <el-radio :label="2">转账</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="发票要求" prop="invoiceType">
              <el-radio-group v-model="addData.invoiceType">
                <el-radio :label="1">不需要发票</el-radio>
                <el-radio :label="2">增值税普通发票</el-radio>
                <el-radio :label="3">增值税专用发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="补充说明" prop="remark">
              <el-input type="textarea" v-model="addData.remark" placeholder="请输入" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="附件上传" prop="filesEntityList">
              <el-upload
                class="upload-demo"
                :action="interAddrss + 'sys/common/upload'"
                :data="{uploadType: 'NEW_ENQUIRY'}"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess2"
                :on-remove="handleRemove2"
                multiple
                :limit="3"
                :file-list="fileList2">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </div>
        </article>
        <aside>
          <h5>竞价规则</h5>
          <div class="asideContent">
            <el-form-item label="报价方式" prop="enquiryRange">
              <el-radio-group v-model="addData.enquiryRange">
                <el-radio :label="1">只允许已互加的供应商</el-radio>
                <el-radio :label="2">指定供应商</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="addData.enquiryRange === 2" label="指定供应商" prop="assignSellId">
              <el-select v-model="addData.assignSellId" placeholder="选择供应商(可多选)" multiple clearable size="mini" collapse-tags>
                <el-option v-for="(item, index) in sellList" :key="index" :label="item.name" :value="item.sellerId" :value-key="index"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </aside>
        <article>
          <h5>其他条款</h5>
          <div class="articleContent">
            <el-form-item label="标书文件" prop="filesEntityList">
              <el-upload
                class="upload-demo"
                :action="interAddrss + 'sys/common/upload'"
                :data="{uploadType: 'NEW_ENQUIRY'}"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess3"
                :on-remove="handleRemove3"
                multiple
                :limit="3"
                :file-list="fileList3">
                <el-button size="small" type="primary">上传标书</el-button>
              </el-upload>
            </el-form-item>
          </div>
        </article>
      </el-form>
    </div>
    <footer>
      <el-button class="lightDeepStyle" @click="submitAskPrice()">发布竞价</el-button>
      <el-button class="defaultStyle" @click="addAskPrice()">保存竞价</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
    <el-dialog class="uploadDialog" title="上传附件" :visible.sync="uploadDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <el-upload
          class="upload-demo"
          drag
          :action="interAddrss + 'sys/common/upload'"
          :data="{uploadType: 'NEW_ENQUIRY'}"
          :headers="headers"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="uploadDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog class="chooseOrderDialog" title="选择产品" :visible.sync="chooseOrderDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <el-input class="sectionInp" v-model="matterData.codeAndName" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
      </div>
      <el-table ref="matterSearchRef" :data="matterManageList" style="width: 100%" max-height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
        <el-table-column type="selection" width="45px" align="center" fixed="left"></el-table-column>
        <el-table-column show-overflow-tooltip prop="code" label="产品编号" min-width="110px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.specs === '' || scope.row.specs === null ? '暂未填写' : scope.row.specs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="newColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.newColour === '' || scope.row.newColour === null ? '暂未填写' : scope.row.newColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productType === 0 ? '成品' : (scope.row.productType === 1 ? '半成品' : (scope.row.productType === 2 ? '物料' : '服务'))}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unit" label="单位" min-width="130px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.unit === null ? '暂未填写' : scope.row.unit}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
        <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addAskPriceApi, submitAskPriceApi,matterListApi, getProvinceAllApi, siteListApi, sellerListApi } from '@/api'
export default {
  data() {
    return {
      // 当前打开的文件列表
      fileList3: [],
      // 供应商列表
      sellList: [],
      // 当前打开的文件列表
      fileList2: [],
      // 收货地址
      addressList: [],
      // 当前打开的产品上传弹框下标
      chooseMatterIndex: '',
      // 当前打开的文件列表
      fileList: [],
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss'),
      headers: {
        'token': window.$cookies.get('token')
      },
      // 级联选择器分类(地区使用)
      defaultData: {
        value: 'code',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover',
        checkStrictly: true
      },
      // 存储全部省市区列表
      provinceList: [],
      addData: {
        name: '',
        enquiryLineEntityList: [],
        roundEntityList: [{
          beginTime: '',
          endTime: '',
          days: '',
          hours: '',
          minutes: '',
          seconds: '',
          round: 1
        }],
        offerType: '',
        offerAsk: 2,
        filesEntityList: [],
        peopleName: '',
        peoplePhone: '',
        jiaofuId: '',
        type: 2,
        numberRound: '',
        state: 0,
        filesTenderEntityList: [],
        provice: [],
        payType: 2,
        invoiceType: 1,
        remark: '',
        enquiryRange: '',
        assignSellId: ''
      },
      // 日期选择器限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7
        }
      },
      rules: {
        name: [
          { required: true, message: '标题不能为空!', trigger: 'blur' },
        ],
        numberRound: [
          { required: true, message: '竞价轮数不能为空!', trigger: 'blur' },
        ],
        beginTime: [
          { required: true, message: '竞价开始日期不能为空!', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '报价截止日期不能为空!', trigger: 'blur' },
        ],
        offerType: [
          { required: true, message: '报价方式不能为空!', trigger: 'blur' },
        ],
        jiaofuId: [
          { required: true, message: '收货地址不能为空!', trigger: 'blur' },
        ],
        provice: [
          { required: true, message: '经营地址不能为空!', trigger: 'blur' },
        ],
        payType: [
          { required: true, message: '支付方式不能为空!', trigger: 'blur' },
        ],
        invoiceType: [
          { required: true, message: '发票要求不能为空!', trigger: 'blur' },
        ],
        enquiryRange: [
          { required: true, message: '报价方式不能为空!', trigger: 'blur' },
        ],
        assignSellId: [
          { required: true, message: '指定供应商不能为空!', trigger: 'blur' },
        ]
      },
      // 选择产品弹框
      chooseOrderDialog: false,
      // 上传附件
      uploadDialog: false,
      // 传参参数
      matterData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        status: "1",
        typeId: "",
        name: "",
        codeAndName: "",
        productType: ""
      },
      // 产品列表
      matterManageList: []
    }
  },
  created() {
    this.getProvince()
    this.getModuleList()
    this.sellerList()
    this.addData.roundEntityList[0].beginTime = this.getTimer()
  },
  methods: {
    // 获取当前年月日星期几几点几分几秒
    getTimer() {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const seconds = String(currentDate.getSeconds()).padStart(2, '0')
      const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      return currentDateTime
    },
    routerJump() {
      this.$router.back()
    },
    // 新增竞价
    async addAskPrice() {
      if (this.addData.enquiryLineEntityList.length === 0) return this.$message.error('产品明细不能为空!')
      let errorOne = true
      let errorTwo = true
      this.addData.enquiryLineEntityList.some(item => {
        if (item.number === '' || item.number  == 0) { 
          errorOne = false
          return false
        }
        if (item.arriveTime === '') { 
          errorTwo = false
          return false
        }
      })
      if (errorOne === false) return this.$message.error('产品明细中采购数量不能为空!')
      if (errorTwo === false) return this.$message.error('产品明细中要求交货日期不能为空!')
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.addData.enquiryRange === 1) {
            this.addData.assignSellId = ''
          } else if (this.addData.enquiryRange === 2) {
            this.addData.assignSellId = (this.addData.assignSellId).join(',')
          }
          const { data: res } = await addAskPriceApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('保存竞价成功!')
          setTimeout(() => {
            this.$router.replace('competePricePurchase')
          }, 300)
        } else {
          return false
        }
      })
    },
    // 发布竞价
    async submitAskPrice() {
      if (this.addData.enquiryLineEntityList.length === 0) return this.$message.error('产品明细不能为空!')
      let errorOne = true
      let errorTwo = true
      this.addData.enquiryLineEntityList.some(item => {
        if (item.number === '' || item.number  == 0) { 
          errorOne = false
          return false
        }
        if (item.arriveTime === '') { 
          errorTwo = false
          return false
        }
      })
      if (errorOne === false) return this.$message.error('产品明细中采购数量不能为空!')
      if (errorTwo === false) return this.$message.error('产品明细中要求交货日期不能为空!')
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.addData.enquiryRange === 1) {
            this.addData.assignSellId = ''
          } else if (this.addData.enquiryRange === 2) {
            this.addData.assignSellId = (this.addData.assignSellId).join(',')
          }
          const { data: res } = await addAskPriceApi(this.addData)
          if (res.code !== 0) return this.$message.error(res.msg)
          const { data: ress } = await submitAskPriceApi(res.data.data.id)
          if (ress.code !== 0) return this.$message.error(ress.msg)
          this.$message.success('发布竞价成功!')
          setTimeout(() => {
            this.$router.replace('competePricePurchase')
          }, 300)
        } else {
          return false
        }
      })
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 1, status: 3 })
      this.sellList = res.data.page.list
    },
    // 选择上传地址
    chooseAddress() {
      this.addressList.forEach((item, index) => {
        console.log(item.id, this.addData.jiaofuId)
        if (item.id == this.addData.jiaofuId) {
          console.log(item.name, this.addData.jiaofuId)
          this.addData.peopleName = item.name
          this.addData.peoplePhone = item.phone
        }
      })
    },
    // 移除上传文件
    handleRemove2(file, fileList) {
      this.fileList2 = fileList
      this.addData.filesEntityList.forEach((item, index) => {
        if (item.url == file.url) {
          this.addData.filesEntityList.splice(index, 1)
          this.$message.success('移除成功!')
          return false
        }
      })
    },
    // 上传成功回调
    handleAvatarSuccess2(res, file) {
      if (res.code === 0) {
        this.fileList2.push({ name: file.name, url: file.response.data.url })
        this.addData.filesEntityList.push({
          fileName: file.name,
          url: file.response.data.url,
          type: 1
        })
        this.$message.success('上传成功!')
      } else {
        this.$message.error('上传失败')
      }
    },
    // 移除上传文件
    handleRemove3(file, fileList) {
      this.fileList3 = fileList
      this.addData.filesTenderEntityList.forEach((item, index) => {
        if (item.url == file.url) {
          this.addData.filesTenderEntityList.splice(index, 1)
          this.$message.success('移除成功!')
          return false
        }
      })
    },
    // 上传成功回调
    handleAvatarSuccess3(res, file) {
      if (res.code === 0) {
        this.fileList3.push({ name: file.name, url: file.response.data.url })
        this.addData.filesTenderEntityList.push({
          fileName: file.name,
          url: file.response.data.url,
          type: 2
        })
        this.$message.success('上传成功!')
      } else {
        this.$message.error('上传失败')
      }
    },
    // 获取收货地址
    async getModuleList() {
      const { data: res } = await siteListApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.addressList = res.data
      // 获取默认地址
      this.addressList.forEach((item, index) => {
        if (item.def === 1) {
          this.addData.jiaofuId = item.id
          this.addData.peopleName = item.name
          this.addData.peoplePhone = item.phone
        }
      })
    },
    // 计算结束日期
    countData(key) {
      if (this.addData.roundEntityList[0].beginTime !== '' && this.addData.roundEntityList[0].endTime !== '' && this.addData.roundEntityList[0].beginTime > this.addData.roundEntityList[0].endTime) {
        if (key === 1) {
          this.addData.roundEntityList[0].beginTime = ''
          return this.$message.error('竞价开始日期不得大于报价截止日期')
        } else {
          this.addData.roundEntityList[0].endTime = ''
          return this.$message.error('报价截止日期不得小于竞价开始日期')
        }
      }
      if (this.addData.roundEntityList[0].beginTime != '' && this.addData.roundEntityList[0].endTime != '') {
        // 结束时间
        let endTime = (Date.parse(new Date(this.addData.roundEntityList[0].endTime)))/1000
        // 开始时间
        let beginTime = (Date.parse(new Date(this.addData.roundEntityList[0].beginTime)))/1000
        //时间差
        let dateDiffer = (endTime - beginTime) * 1000
        //计算出相差天数
        this.addData.roundEntityList[0].days = Math.floor(dateDiffer / (24 * 3600 * 1000))
        console.log(this.addData.roundEntityList[0].days, '666666666')
        //计算出小时数
        let leave1 = dateDiffer % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        this.addData.roundEntityList[0].hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        this.addData.roundEntityList[0].minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        this.addData.roundEntityList[0].seconds = Math.round(leave3 / 1000);
      }
    },
    // 移除上传文件
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.addData.enquiryLineEntityList[this.chooseMatterIndex].filesEntityList.forEach((item, index) => {
        if (item.url == file.url) {
          this.addData.enquiryLineEntityList[this.chooseMatterIndex].filesEntityList.splice(index, 1)
          this.$message.success('移除成功!')
          return false
        }
      })
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      console.log(res, file)
      if (res.code === 0) {
        this.fileList.push({name: file.name, url: file.response.data.url})
        this.addData.enquiryLineEntityList[this.chooseMatterIndex].filesEntityList.push({
          fileName: file.name,
          url: file.response.data.url,
          type: 3
        })
        this.$message.success('上传成功!')
      } else {
        this.$message.error('上传失败')
      }
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过5MB!')
      }
      return isLt2M
    },
    // 打开上传附件弹框
    openUpload(index, value) {
      this.chooseMatterIndex = index
      // 先清空附件列表
      this.fileList = []
      if (value.filesEntityList.length !== 0) {
        value.filesEntityList.forEach((item, index) => {
          this.fileList.push({ name: item.fileName, url: item.url })
        })
      }
      
      this.uploadDialog = true
    },
    // 删除物料
    delMatter(index) {
      this.$confirm('删除当前产品对应附件也需重新上传,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        this.addData.enquiryLineEntityList.splice(index, 1)
        this.$message.success('删除成功!')
      })
    },
    // 获取省市区信息
    async getProvince() {
      const { data: res } = await getProvinceAllApi()
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.provinceList = res.data.list
      this.provinceList.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.newChildList.forEach((items, index) => {
            if (items.childList.length !== 0) {
              items.newChildList = items.childList
            }
          })
        }
      })
    },
    // 级联选择器改变触发
    handleChange(e) {
      let addressName = this.$refs.cascaderRef.getCheckedNodes()[0].pathLabels
      this.addData.provinceId = e[0]
      this.addData.provinceName = addressName[0]
      if (e[1] != undefined) {
        this.addData.cityId = e[1]
        this.addData.cityName = addressName[1]
      } else {
        this.addData.cityId = ''
        this.addData.cityName = ''
      }
      if (e[2] != undefined) {
        this.addData.areaId = e[2]
        this.addData.areaName = addressName[2]
      } else {
        this.addData.areaId = ''
        this.addData.areaName = ''
      }
      this.$refs.cascaderRef.dropDownVisible = false
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.addData.enquiryLineEntityList[index].number = temp;
    },
    // 打开添加弹框
    openAddDialog() {
      this.getMatterManageList()
      this.chooseOrderDialog = true
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 获取产品列表
    async getMatterManageList() {
      const { data: res } = await matterListApi(this.matterData);
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.page.list
      // 去除列表重复项
      // if (this.addData.lineEntityList.legnth !== 0) {
      //   this.addData.lineEntityList.forEach(item => {
      //     newMatterManageList.forEach((val, index) => {
      //       if (item.productId === val.productId) {
      //         newMatterManageList.splice(index, 1)
      //       }
      //     })
      //   })
      // }
      this.matterManageList = newMatterManageList
    },
    // 产品选择
    chooseMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          productId: item.id,
          productCode: item.code,
          productName: item.name,
          productSpecs: item.specs,
          productNewColour: item.newColour,
          unitName: item.unit,
          number: '',
          remark: '',
          arriveTime: '', //要求交货时间
          filesEntityList: []
        }
        this.addData.enquiryLineEntityList.push(newData)
      })
      this.chooseOrderDialog = false
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    }
  }
}
</script>
<style scoped lang='less'>
.addAskPrice {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addAskPriceContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 190px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 124px;
      background: #fff;
      box-sizing: border-box;
      border-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      /deep/.el-form-item {
        margin: 0 0 20px 0;
        width: 100%;
        .el-form-item__error {
          padding: 5px 0 0 0;
        }
        .el-form-item__label {
          text-align: left;
        }
        .el-form-item__content {
          width: calc(100% - 122px);
          .el-input {
            width: 100%;
          }
          .el-select {
            width: 100%;
          }
        }
      }
    }
    section {
      position: relative;
      margin-top: 20px;
      margin-bottom: 2px;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-radius: 10px;
      h5 {
        margin: 0 0 40px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
    }
    main {
      position: relative;
      margin-top: 20px;
      margin-bottom: 2px;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-radius: 10px;
      h5 {
        margin: 0 0 40px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .mainContent {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 25%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__label {
            text-align: left;
          }
          .el-form-item__content {
            width: calc(100% - 122px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .el-date-editor {
              width: calc(100% - 40px)!important;
            }
          }
        }
      }
    }
    article {
      position: relative;
      margin-top: 20px;
      margin-bottom: 2px;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-radius: 10px;
      h5 {
        margin: 0 0 40px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .articleContent {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 50%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__label {
            text-align: left;
          }
          .el-form-item__content {
            width: calc(100% - 122px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .el-cascader {
              width: 100%;
            }
          }
        }
        /deep/ .el-form-item:nth-child(3) {
          width: 100%;
        }
        /deep/ .el-form-item:nth-child(4) {
          width: 100%;
        }
        /deep/ .el-form-item:nth-child(5) {
          width: 100%;
        }
        .el-upload__tip {
          margin-left: 20px;
          display: inline-block;
        }
      }
    }
    aside {
      position: relative;
      margin-top: 20px;
      margin-bottom: 2px;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-radius: 10px;
      h5 {
        margin: 0 0 40px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .asideContent {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 50%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__label {
            text-align: left;
          }
          .el-form-item__content {
            width: calc(100% - 122px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .el-cascader {
              width: 100%;
            }
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 685px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
  /deep/ .uploadDialog {
    .el-dialog {
      width: 400px;
      height: 450px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        padding-bottom: 0;
        height: 340px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
