import { render, staticRenderFns } from "./addAskPrice.vue?vue&type=template&id=96fdad38&scoped=true&"
import script from "./addAskPrice.vue?vue&type=script&lang=js&"
export * from "./addAskPrice.vue?vue&type=script&lang=js&"
import style0 from "./addAskPrice.vue?vue&type=style&index=0&id=96fdad38&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96fdad38",
  null
  
)

export default component.exports