// 修改采购计划单
<template>
  <div class="addPurchasePlan">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/purchasePlanManage' }">采购计划单</el-breadcrumb-item>
      <el-breadcrumb-item v-if="addData.approveStatus === 0" class="nowPage" style="color: rgba(18, 62, 131, 1)">修改计划单</el-breadcrumb-item>
      <el-breadcrumb-item v-else class="nowPage" style="color: rgba(18, 62, 131, 1)">变更计划单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="addPurchasePlanContent">
      <el-form ref="ruleForm" :model="addData" label-width="117px" :rules="rules">
        <nav>
          <h5 v-if="addData.approveStatus === 3">
            变更计划单
            <span class="stateBox2">驳回</span>
          </h5>
          <h5 v-else>
            修改计划单
            <span class="stateBox1" v-if="addData.approveStatus === 0">制单</span>
            <span class="stateBox1" v-if="addData.approveStatus === 1">待审核</span>
            <span class="stateBox1" v-if="addData.approveStatus === 5">进行中</span>
            <span class="stateBox3" v-if="addData.approveStatus === 2">审批完成</span>
            <span class="stateBox3" v-if="addData.approveStatus === 4">手动结束</span>
          </h5>
          <h5>计划单编号: {{addData.code}}</h5>
          <div class="navBox">
            <el-form-item label="申请人:" prop="proposerId">
              <!-- @change="clearValue()" -->
              <el-select v-model="addData.proposerId" placeholder="选择申请人" clearable size="mini">
                <el-option v-for="(item, index) in userManageList" :key="index" :label="item.name" :value="item.userId" :value-key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采购类型" prop="type">
              <el-radio-group v-model="addData.type">
                <el-radio :label="0">原材料采购</el-radio>
                <el-radio :label="1">外协生产订单</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="申请日期:" prop="createTime">
              <el-date-picker :picker-options="pickerOptions" v-model="addData.createTime" type="date" placeholder="申请日期选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="开始采购日期:" prop="buyTime">
              <el-date-picker :picker-options="pickerOptions" v-model="addData.buyTime" type="date" placeholder="开始采购日期选择" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
            </el-form-item>
          </div>
        </nav>
        <main>
          <h5>采购清单明细</h5>
          <el-button v-if="addData.approveStatus === 0 || addData.approveStatus === 3" class="lightDeepStyle addBtn" @click="openAddDialog()"><i class="el-icon-plus"></i>添加产品</el-button>
          <el-button v-if="addData.approveStatus === 0 || addData.approveStatus === 3" class="defaultStyle delBtn" @click="delMatter('all')">删除</el-button>
          <ul v-if="addData.lineEntityList.length !== 0">
            <el-checkbox-group v-model="chooseList">
              <li v-for="(item, index) in addData.lineEntityList" :key="index">
                <div class="liUp">
                  <p>
                    <el-checkbox :label="item.productId"><span>{{item.code + '-' + item.productName + '-' + (item.unit === null || item.unit === undefined ? '暂无' : item.unit) + '-' + (item.productSpecs === null || item.productSpecs === undefined ? '暂无' : item.productSpecs) + '-' + (item.productNewColour === null || item.productNewColour === undefined ? '暂无' : item.productNewColour)}}</span></el-checkbox>
                    <i v-if="addData.approveStatus === 0 || addData.approveStatus === 3" @click="delMatter(index)" class="el-icon-delete"></i>
                  </p>
                </div>
                <div class="liDown">
                  <el-form-item label="需求数量:" :prop="'lineEntityList.' + index + '.number'" :rules="rules.number">
                    <el-input v-if="addData.approveStatus === 0 || addData.approveStatus === 3" type="number" @input="formatNum(item.number, index, item.numberMax)" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.number" placeholder="输入数值"></el-input>
                    <el-input v-else disabled type="number" @input="formatNum(item.number, index, item.numberMax)" oninput="if(value.length > 10) value=value.slice(0, 10)" v-model="item.number" placeholder="输入数值"></el-input>
                  </el-form-item>
                  <el-form-item label="期望到货日期:" :prop="'lineEntityList.' + index + '.arriveTime'" :rules="rules.arriveTime">
                    <el-date-picker v-model="item.arriveTime" type="date" placeholder="申请日期选择" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="期望供应商:">
                    <el-select v-model="item.sellId" placeholder="选择供应商" clearable size="mini">
                      <el-option v-for="(item, index) in sellList" :key="index" :label="item.name" :value="item.sellerId" :value-key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:30%; margin-top: -2%;">
            <span style="display: block; margin-top: -5%">暂无数据</span>
          </div>
        </main>
      </el-form>
      <!-- 采购单弹框 -->
      <el-dialog class="chooseOrderDialog" title="选择产品" :visible.sync="chooseOrderDialog" :close-on-click-modal="false" v-dialogDrag>
        <div>
          <el-input class="sectionInp" v-model="matterManageData.codeAndName" placeholder="产品编号/产品名称搜索" @input="getMatterManageList()" prefix-icon="el-icon-search"></el-input>
        </div>
        <el-table ref="matterSearchRef" :data="matterManageList" style="width: 100%" height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
          <el-table-column type="selection" width="45px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="code" label="产品编号" min-width="110px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="产品名称" min-width="120px" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.specs === '' || scope.row.specs === null ? '暂未填写' : scope.row.specs}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="newColour" label="颜色" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.newColour === '' || scope.row.newColour === null ? '暂未填写' : scope.row.newColour}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productType === 0 ? '成品' : (scope.row.productType === 1 ? '半成品' : (scope.row.productType === 2 ? '物料' : '服务'))}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="unit" label="单位" min-width="130px" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.unit === null ? '-' : scope.row.unit}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button class="defaultStyle cancleBtn" @click="chooseOrderDialog = false">取 消</el-button>
          <el-button class="lightDeepStyle" @click="chooseMatter()">确 认</el-button>
        </div>
      </el-dialog>
    </div>
    <footer>
      <!-- 审批状态 0 制单 1进行中 2 审批完成 3驳回 4 手动结束 -->
      <el-button v-if="addData.approveStatus === 3" class="lightDeepStyle" @click="addPurchasePlan(2)">变更</el-button>
      <el-button v-else class="lightDeepStyle" @click="addPurchasePlan()">保存</el-button>
      <el-button v-if="addData.approveStatus === 0" class="lightDeepStyle" @click="addPurchasePlan(1)">提交</el-button>
      <el-button class="defaultStyle" @click="routerJump()">返回</el-button>
    </footer>
  </div>
</template>
<script>
import { addPurchasePlanApi, changePurchasePlanApi, submitPurchasePlanApi, matterListApi, getUserManageApi, sellerListApi, purchasePlanDetailApi } from '@/api'
export default {
  data() {
    return {
      // 日期选择器限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now()
        }
      },
      // 控制弹框
      chooseOrderDialog: false,
      // 修改参数
      addData: {
        id: '',
        code: '',
        type: '',
        proposerId: '',
        createTime: '',
        buyTime: '',
        approveStatus: '',
        lineEntityList: []
      },
      // 申请人列表传参
      userManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 999,
        // 搜索内容
        name: "",
        companyId: window.$cookies.get("companyId"),
        state: "",
        isActivate: "",
        deptId: ''
      },
      // 申请人列表
      userManageList: [],
      // 选中的项
      chooseList: [],
      // 产品列表参数
      matterManageData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 9999,
        status: "1",
        typeId: "",
        name: "",
        codeAndName: "",
        productType: ""
      },
      // 产品列表
      matterManageList: [],
      // 选中的物料
      matterList: [],
      // 校验
      rules: {
        type: [
          { required: true, message: '采购类型不能为空!', trigger: 'blur' },
        ],
        proposerId: [
          { required: true, message: '申请人不能为空!', trigger: 'blur' },
        ],
        createTime: [
          { required: true, message: '申请日期不能为空!', trigger: 'blur' },
        ],
        buyTime: [
          { required: true, message: '开始采购日期不能为空!', trigger: 'blur' },
        ],
        number: [
          { required: true, message: '需求量不能为空!', trigger: 'blur' },
        ],
        arriveTime: [
          { required: true, message: '期望到货日期不能为空!', trigger: 'blur' },
        ]
      },
      // 供应商列表
      sellList: []
    }
  },
  created() {
    this.addData.id = JSON.parse(this.$route.query.result)
    this.getUserManage()
    this.sellerList()
    this.getPurchasePlanDetail()
  },
  mounted() {
  },
  activated () {
  },
  methods: {
    // 获取采购计划单详情
    async getPurchasePlanDetail() {
      const { data: res } = await purchasePlanDetailApi(this.addData.id)
      if (res.code !== 0) return this.$message.error(res.msg)
      let newData = res.data.data
      this.addData = {
        id: newData.id,
        code: newData.code,
        type: newData.type,
        proposerId: newData.proposerId,
        createTime: newData.createTime,
        buyTime: newData.buyTime,
        approveStatus: newData.approveStatus,
        lineEntityList: []
      }
      newData.lineEntityList.forEach((item, index) => {
        let array = {
          id: item.id,
          code: item.productCode,
          productId: item.productId,
          productName: item.productName,
          productSpecs: item.productSpecs,
          productNewColour: item.productNewColour,
          unit: item.unitName,
          number: item.number,
          arriveTime: item.arriveTime,
          sellId: item.sellId,
          residueNumber: item.residueNumber
        }
        this.addData.lineEntityList.push(array)
      })
    },
    // 申请人列表
    async getUserManage() {
      const { data: res } = await getUserManageApi(this.userManageData)
      this.userManageList = res.data.page.list
    },
    // 供应商列表
    async sellerList() {
      const { data: res } = await sellerListApi({ search: '', page: 1, size: 9999, roleType: 1, status: 3 })
      this.sellList = res.data.page.list
    },
    // 打开添加弹框
    openAddDialog() {
      this.getMatterManageList()
      this.chooseOrderDialog = true
      // 清空勾选项
      setTimeout(() => {
        this.matterList = []
        this.$refs.matterSearchRef.clearSelection()
      })
    },
    // 获取产品列表
    async getMatterManageList() {
      const { data: res } = await matterListApi(this.matterManageData);
      if (res.code !== 0) return this.$message.error(res.msg)
      let newMatterManageList = res.data.page.list
      // // 去除列表重复项
      // if (this.addData.lineEntityList.legnth !== 0) {
      //   this.addData.lineEntityList.forEach(item => {
      //     newMatterManageList.forEach((val, index) => {
      //       if (item.productId === val.id) {
      //         newMatterManageList.splice(index, 1)
      //       }
      //     })
      //   })
      // }
      this.matterManageList = newMatterManageList
    },
    // 物料选择
    chooseMatter() {
      if (this.matterList.length === 0) return this.$message.error('请先选择产品!')
      this.matterList.forEach((item, index) => {
        let newData = {
          code: item.code,
          productId: item.id,
          productName: item.name,
          productSpecs: item.specs,
          productNewColour: item.newColour,
          unit: item.unit,
          number: '',
          arriveTime: '',
          sellId: ''
        }
        this.addData.lineEntityList.push(newData)
      })
      this.chooseOrderDialog = false
    },
    // 删除产品
    delMatter(ind) {
      if (ind === 'all') {
        if (this.chooseList.length === 0) return this.$message.error('请先勾选需要删除的产品')
        this.$confirm('是否删除勾选产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          console.log(this.chooseList, '44000')
          this.chooseList.forEach(i => {
            this.addData.lineEntityList.forEach((val, index) => {
              if (i === val.productId) {
                this.addData.lineEntityList.splice(index, 1)
              }
            })
          })
          // 清空选中项
          this.chooseList = []
        })
      } else {
        this.$confirm('是否确认删除当前产品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.addData.lineEntityList.splice(ind, 1)
        })
      }
    },
    // 控制只能输入数字且小数点后只能保留2位
    formatNum(val, index, max) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      // 判断计划数不得大于最大可计划数
      if (temp > max) {
        temp = max
      }
      this.addData.lineEntityList[index].number = temp;
    },
    // 返回
    routerJump() {
      this.$router.back()
    },
    // 修改计划单
    addPurchasePlan(val) {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.addData.lineEntityList.length == 0) return this.$message.error('清单明细不能为空!')
          if (val === 2) {
            // 走变更
            const { data: res } = await changePurchasePlanApi(this.addData)
            if (res.code !== 0) return this.$message.error(res.msg)
            this.$message.success('变更成功!')
            setTimeout(() => {
              this.$router.back()
            }, 300)
          } else {
            // 走修改
            if ((this.addData.createTime).length < 11) {
              this.addData.createTime = this.addData.createTime + ' 00:00:00'
            }
            if ((this.addData.buyTime).length < 11) {
              this.addData.buyTime = this.addData.buyTime + ' 00:00:00'
            }
            const { data: res } = await addPurchasePlanApi(this.addData)
            if (res.code !== 0) return this.$message.error(res.msg)
            // 判断是否直接提交
            if (val === 1) {
              this.submitPurchasePlan(res.data.data.id)
            } else {
              this.$message.success('修改成功!')
              setTimeout(() => {
                this.$router.back()
              }, 300)
            }
          }
        } else {
          return false
        }
      })
    },
    // 提交计划单
    async submitPurchasePlan(id) {
      const { data: res } = await submitPurchasePlanApi(id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('提交成功!')
      setTimeout(() => {
        this.$router.back()
      }, 300)
    },
    // -----------------------------------------------
    // 清空列表数据
    clearValue() {
      if (this.addData.lineEntityList.length !== 0) {
        this.$confirm('变更供应商将清空已添加的计划明细?', '变更确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          // 清空选中项
          this.addData.lineEntityList = []
        })
      }
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.matterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.matterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.matterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.matterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.matterList.push(selection)
        }
      } else {
        this.matterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.matterList = selection
    }
  }
}
</script>
<style scoped lang='less'>
.addPurchasePlan {
  .nowPage {
    /deep/ .el-breadcrumb__inner {
      color: rgba(18, 62, 131, 1);
    }
  }
  .addPurchasePlanContent {
    margin: 20px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 6px;
    padding: 0 3px;
    width: calc(100% - 40px);
    height: calc(100vh - 180px);
    border-radius: 10px;
    overflow-y: auto;
    nav {
      margin-bottom: 2px;
      padding: 20px;
      height: 206px;
      background: #fff;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h5 {
        margin: 0 0 20px 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
        .stateBox1, .stateBox2, .stateBox3 {
          margin-left: 10px;
          display: inline-block;
          width: 64px;
          height: 22px;
          border-radius: 4px 4px 4px 4px;
          font-size: 12px;
          background: rgba(59,212,144,0.05);
          border: 1px solid rgba(59,212,144,0.2);
          color: rgba(59, 212, 144, 1);
          text-align: center;
          line-height: 22px;
        }
        .stateBox2 {
          background: rgba(239, 188, 96, 0.05);
          border: 1px solid rgba(239, 188, 96, 0.2);
          color: rgba(239, 188, 96, 1);
        }
        .stateBox3 {
          background-color: rgba(70, 147, 235, 0.05);
          border-color: rgba(70, 147, 235, 0.20);
          color: rgba(70, 147, 235, 1);
        }
      }
      .navBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.80);
        /deep/.el-form-item {
          margin: 0 0 20px 0;
          width: 33.3%;
          .el-form-item__error {
            padding: 5px 0 0 0;
          }
          .el-form-item__content {
            width: calc(100% - 117px);
            .el-input {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
      }
      /deep/.el-form-item:nth-child(4) {
        width: 100%;
      }
    }
    main {
      position: relative;
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      h5 {
        margin: 0;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.8);
        line-height: 19px;
      }
      .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        i {
          margin-right: 5px;
        }
      }
      .delBtn {
        margin: 20px 0 0 0;
        width: 56px;
        height: 32px;
        background: #FFFFFF;
      }
      ul {
        li {
          padding: 20px 0;
          height: 106px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          .liUp {
            display: flex;
            justify-content: space-between;
            .el-checkbox .el-checkbox__label {
              color: rgba(0, 0, 0, 0.60);
            }
            >p {
              height: 24px;
              width: 100%;
              font-size: 14px;
              >span {
                margin-left: 30%;
                display: inline-block;
                width: 220px;
                color: rgba(0, 0, 0, 0.60);
              }
              i {
                float: right;
                cursor: pointer;
                color: red;
                font-size: 20px;
              }
            }
          }
          .liDown {
            position: relative;
            margin-top: 10px;
            display: flex;
            height: 32px;
            .el-form-item {
              margin: 0;
              width: 33.3%;
              .el-form-item__content {
                width: calc(100% - 117px);
                .el-input {
                  width: 100%;
                }
                .el-select {
                  width: 100%;
                  /deep/ .el-input {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .emptyBg {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        img {
          height: auto;
        }
        span {
          margin-bottom: 60px;
          display: block;
          color: #ccc;
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    padding: 12px 20px;
    height: 56px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.05);
  }
  /deep/ .chooseOrderDialog {
    .el-dialog {
      width: 680px;
      height: 474px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 20px 20px 0;
        vertical-align: top;
        border-radius: 16px;
        .el-dialog__headerbtn {
          top: 20px;
        }
      }
      .el-dialog__body {
        padding-bottom: 0;
        height: 354px!important;
        width: 100%;
        .sectionInp {
          margin: 20px 20px 20px 0;
          height: 32px;
          width: 100%;
          display: flex;
          align-items: center;
          /deep/.el-input {
            width: 160px !important;
            font-size: 12px;
            .el-input__inner {
              font-size: 12px;
            }
          }
        }
        .el-table {
          margin-top: 5px;
          height: 325px;
          td {
            padding: 0;
            .cell {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
      .el-dialog__footer {
        height: 72px;
        // border-top: 0.3px solid #EBEEF5;
        border-top: 0;
        .dialog-footer {
          width: 100%;
          height: 72px;
          .el-button {
            position: absolute;
            right: 70px;
            margin-top: 20px;
            padding: 0;
            height: 32px;
            width: 60px;
            border-radius: 4px;
            line-height: 0;
          }
          .cancleBtn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
