<template>
  <div class="home_contaner">
    <!-- 侧边栏 -->
    <el-aside :width="isCollapse ? '80px':'150px'">
      <div class="logo">
        <img src="../assets/img/位图.png" @click="routerJump('index', '', '工作台')" />
        <span v-if="isCollapse === false" @click="routerJump('index', '', '工作台')">炙讯云-SRM</span>
      </div>
      <ul class="asideBox" v-if="navMenu[0]">
        <li v-for="(item, i) in navMenu[0].list" :key="i">
          <div v-if="isCollapse === false">
            <p>{{item.name}}</p>
            <span :class="menu.name === chooseMenu ? 'highlight' : ''" @click="routerJump(menu.url, menu.list, menu.name)" v-for="(menu, index) in item.list" :key="index"><i :class="menu.icon"></i>{{menu.name}}<u v-if="menu.name == chooseMenu"></u></span>
          </div>
          <div v-else>
            <p style="padding-left: 0; text-align: center;">{{item.name}}</p>
            <span style="padding-left: 0; text-align: center;" :class="menu.name === chooseMenu ? 'highlight' : ''" @click="routerJump(menu.url, '', menu.name)" v-for="(menu, index) in item.list" :key="index"><i style="margin-right: 0" :class="menu.icon"></i></span>
          </div>
        </li>
      </ul>
    </el-aside>
    <el-container>
      <!-- 主体头部 -->
      <el-header>
        <a @click="hiddenAside">
          <i style="vertical-align: middle;" :class="isCollapse === false ? 'iconfont icon-zu48': 'iconfont icon-zu-1'"></i>
        </a>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link company-link">
            <span>{{company}}</span>
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <a v-for="item in moreCompanyList" :key="item.companyId" @click="updateDefaultCompany(item)">
              <el-dropdown-item style="width: 180px">
                <el-tooltip v-if="item.companyName.length > 12" class="item" effect="dark" :content="item.companyName" placement="right">
                  <span class="textHidden" :style="item.status === 0? 'display: inline-block; width: 180px; height: 24px; color: #FFC300; font-weight:bold': (item.status === 3 ? 'display: inline-block; width: 180px; height: 24px; color: #FA5151; font-weight:bold' : 'display: inline-block; width: 180px; height: 24px; color: #000; font-weight:bold')">{{item.companyName}}</span>
                </el-tooltip>
                <span v-else class="textHidden" :style="item.status === 0? 'display: inline-block; width: 180px; height: 24px; color: #FFC300; font-weight:bold': (item.status === 3 ? 'display: inline-block; width: 180px; height: 24px; color: #FA5151; font-weight:bold' : 'display: inline-block; width: 180px; height: 24px; color: #000; font-weight:bold')">{{item.companyName}}</span>
              </el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-if="userLevel !== ''" class="iSpan">{{userLevel}}</span>
        <el-button v-if="approveStatus===1 ||approveStatus===3" type="danger" style="width: 100px;height: 30px;margin-left: 20px; font-size: 16px;" @click="approve">企业认证</el-button>
        <div class="el_header_right">
          <div class="loginChangeBox" @click="changeRole()">
            <span>采购端</span>
            <span>供应端</span>
            <p :class="chooseRole === true ? '' : 'chooseBox'">{{chooseRole === true ? '采购端' : '供应端'}}</p>
          </div>
          <!-- <span class="helpCenter" @click="helpCenterJump()">帮助中心</span> -->
          <el-button v-if="quitStatus !== '1'" class="messageBtn" @click="routerPush('manageCenter')">
            <i class="iconfont icon-zu1"></i>
          </el-button>
          <el-button @click="screenfull()">
            <i
              :class="isFullscreen === false ? 'iconfont icon-lujing-21': 'iconfont icon-lujing-155'"
            ></i>
          </el-button>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <img v-if="imgAdress === ''" src="../assets/img/defaultHead.png" alt />
              <img v-else :src="imgAdress" alt />
              <span>{{name}}</span>
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <a v-if="quitStatus !== '1'" @click="routerPush('personalCenter')">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </a>
              <a v-if="quitStatus !== '1'" @click="dialogVisible = true">
                <el-dropdown-item>密码修改</el-dropdown-item>
              </a> -->
              <!-- <a v-if="quitStatus !== '1'"><el-dropdown-item>手机号修改</el-dropdown-item></a> -->
              <!-- <a @click="openCreateNewCompany()">
                <el-dropdown-item>创建新公司</el-dropdown-item>
              </a> -->
              <a @click="approve">
                <el-dropdown-item>企业认证{{ approveStatus===1?'(未认证)':approveStatus===2?'(已认证)':'(驳回)' }}</el-dropdown-item>
                <!-- <el-dropdown-item  v-else-if="approveStatus==='1'">企业认证(已认证)</el-dropdown-item>
                <el-dropdown-item v-else-if="approveStatus==='3'">企业认证</el-dropdown-item> -->
              </a>
              <a @click="logout()">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <!-- 主体内容区 -->
      <el-main v-if="quitStatus !== '1'">
        <router-view></router-view>
      </el-main>
      <el-main v-else>
        <div class="quitBox">
          <el-button size="mini" @click="openCreateNewCompany()">创建新公司</el-button>
        </div>
      </el-main>
    </el-container>
    <!-- 修改密码弹出框 -->
    <template id="changePasswordBox">
      <div>
        <el-dialog
          title="修改密码"
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          v-dialogDrag
        >
          <!-- 注册表单 -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="password">
              <p>新密码:</p>
              <el-input
                v-model="ruleForm.password"
                placeholder="设置六至二十位登录密码"
                type="password"
                prefix-icon="el-icon-search"
                clearable
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item prop="checkPassword">
              <p>再次确认:</p>
              <el-input
                v-model="ruleForm.checkPassword"
                placeholder="请再次输入登录密码"
                type="password"
                prefix-icon="el-icon-search"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="mobile">
              <p>绑定手机:</p>
              <a
                style="color: rgba(27, 199, 181, 1)"
              >{{ruleForm.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}</a>
            </el-form-item>
            <el-form-item prop="code" class="el_form_item_shortMsg">
              <p>验证码:</p>
              <el-input v-model="ruleForm.code" name="shortMsg" placeholder="请输入短信验证码" clearable></el-input>
              <el-button
                class="lightDeepStyle"
                @click="sendVerification('ruleForm')"
                ref="sendVeri"
                :disabled="controlBtn"
              >发送验证码</el-button>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="searchStyle" @click="dialogVisible = false">取消</el-button>
            <el-button class="lightDeepStyle" @click="changePassword">修改</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <!-- 创建新公司弹出框 -->
    <template class="createNewCompanyBox">
      <div>
        <el-dialog
          :title="controlAddCompany === true ? '创建新公司' : '再次提交申请'"
          :visible.sync="createNewCompanyDialog"
          :close-on-click-modal="false"
          class="createNewCompany"
          v-dialogDrag
        >
          <!-- 注册表单 -->
          <el-form :model="ruleForm" ref="ruleForm">
            <el-form-item>
              <p>公司名称:</p>
              <el-input
                v-model="createCompanyData.name"
                placeholder="请输入公司名称"
                prefix-icon="el-icon-search"
                clearable
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <p>注册号:</p>
              <el-input
                v-model="createCompanyData.regNumber"
                placeholder="请填写注册号"
                prefix-icon="el-icon-search"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="choose" class="el_form_agree">
              <el-checkbox v-model="agreeChooseBtn"></el-checkbox>
              <span>
                勾选同意
                <el-button
                  type="text"
                  @click="dialogUserAccess = true"
                  style="color: rgba(27, 199, 181, 1)"
                >《用户服务协议》</el-button>
              </span>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="searchStyle" @click="createNewCompanyDialog = false">取消</el-button>
            <el-button
              v-if="controlAddCompany === true"
              class="lightDeepStyle"
              @click="createNewCompany()"
            >创建</el-button>
            <el-button
              v-if="controlAddCompany === false"
              class="lightDeepStyle"
              @click="createOldCompany()"
            >再次申请</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <!-- 用户服务协议 -->
    <template id="user">
      <div>
        <el-dialog
          class="userAgreement"
          title="用户隐私和服务条款"
          :visible.sync="dialogUserAccess"
          :close-on-click-modal="false"
          v-dialogDrag
        >
          <h2>一、总则</h2>
          <p>1.1 用户应当同意本协议的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中勾选"我已阅读并接受"模块即表示用户与产品名称达成协议，完全接受本协议项下的全部条款。</p>
          <p>1.2 用户注册成功后，产品名称将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
          <p>1.3 用户可以使用产品名称各个频道单项服务，当用户使用产品名称各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及产品名称在该单项服务中发出的各类公告的同意。</p>
          <h2>二、注册信息和隐私保护</h2>
          <p>2.1 产品名称帐号（即产品名称用户ID）的所有权归产品名称，用户完成注册申请后，获得产品名称帐号的使用权。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，产品名称不负任何责任。</p>
          <p>2.2 用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用，应立即通知产品名称。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，产品名称不承担任何责任。</p>
          <p>
            2.3 产品名称不对外公开或向第三方提供单个用户的注册资料，除非：
            <br />• 事先获得用户的明确授权；
            <br />• 只有透露您的个人资料，才能提供您所要求的产品和服务；
            <br />• 根据有关的法律法规要求；
            <br />• 按照相关政府主管部门的要求；
            <br />• 为维护产品名称的合法权益。
          </p>
          <h2>三、使用规则</h2>
          <p>
            3.1 用户在使用产品名称服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶
            <br />• 上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：
            <br />• 不得为任何非法目的而使用网络服务系统
            <br />不利用产品名称服务从事以下活动：
            <br />• 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
            <br />• 未经允许，对计算机信息网络功能进行删除、修改或者增加的；
            <br />• 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
            <br />• 故意制作、传播计算机病毒等破坏性程序的；
            <br />• 其他危害计算机信息网络安全的行为。
          </p>
          <p>3.2 用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿数据堂与合作公司、关联公司，并使之免受损害。对此，产品名称有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收产品名称帐号、追究法律责任等措施。对恶意注册产品名称帐号或利用产品名称帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，产品名称有权回收其帐号。同时，产品名称会视司法部门的要求，协助调查。</p>
          <p>3.3 用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
          <p>3.4 用户须对自己在使用产品名称服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在产品名称首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予产品名称等额的赔偿。</p>
          <h2>四、服务内容</h2>
          <p>4.1 产品名称网络服务的具体内容由产品名称根据实际情况提供。</p>
          <p>4.2 除非您与产品名称另有约定，您同意本服务仅为您个人非商业性质的使用。</p>
          <p>4.3 产品名称的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。</p>
          <p>4.4 产品名称可能根据实际需要对收费服务的收费标准、方式进行修改和变更，产品名称也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，产品名称将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。</p>
          <p>4.5 产品名称网络需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，产品名称网络无需为此承担任何责任。产品名称网络保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</p>
          <p>4.6 本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于产品名称网络无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，产品名称网络不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，产品名称网络亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，产品名称网络不承担任何责任。</p>
          <p>4.7 产品名称网络对在服务网上得到的任何商品购物服务、交易进程、招聘信息，都不作担保。</p>
          <p>4.8 产品名称网络有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，产品名称对用户和任何第三人均无需承担任何责任。</p>
          <p>
            4.9 终止服务
            <br />您同意产品名称得基于其自行之考虑，因任何理由，包含但不限于产品名称认为您已经违反本服务协议的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，产品名称可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，产品名称对您或任何第三人均不承担任何责任。
          </p>
          <h2>五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</h2>
          <p>5.1 产品名称在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归产品名称所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。</p>
          <p>5.2 除另有特别声明外，产品名称提供本服务时所依托软件的著作权、专利权及其他知识产权均归产品名称所有。</p>
          <p>5.3 产品名称在本服务中所使用的“产品名称”等商业标识，其著作权或商标权归产品名称所有。</p>
          <p>5.4 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经产品名称、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</p>
          <h2>六、未成年人使用条款</h2>
          <p>6.1 若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用本服务。</p>
          <p>
            6.2 未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：
            <br />（1）认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；
            <br />（2）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
            <br />（3）在监护人或老师的指导下，学习正确使用网络；
            <br />（4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
          </p>
          <h2>七、其他</h2>
          <p>7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
          <p>7.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向产品名称所在地的人民法院提起诉讼。</p>
          <p>7.3 产品名称未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
          <p>
            7.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
            请您在发现任何违反本服务协议以及其他任何单项服务的服务条款、产品名称各类公告之情形时，通知产品名称。
          </p>
          <span slot="footer" class="dialog-footer">
            <el-button @click="disAgree">不同意</el-button>
            <el-button type="primary" @click="agree">同 意</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <!-- 完善企业信息弹出框 -->
    <template class="perfectCompanyBox">
      <div>
        <el-dialog
          title="完善企业信息"
          :visible.sync="perfectCompanyDialog"
          :close-on-click-modal="false"
          :show-close="false"
          class="perfectCompanyStyle"
          v-dialogDrag
        >
          <!-- 注册表单 -->
          <el-form :model="ruleForm" ref="ruleForm">
            <el-form-item style="position: relative">
              <p>公司名称</p>
              <el-input
                v-model="perfectCompanyData.name"
                placeholder="输入公司名称"
                clearable
                autocomplete="off"
                style="width: 240px"
              ></el-input>
              <el-button class="lightDeepStyle" @click="registerSeller(1)">检测</el-button>
              <p style="position: absolute; top: 28px; left: 90px; font-size: 13px; color: #ccc;" v-if="companyCheck === 0">待检测</p>
              <p style="position: absolute; top: 28px; left: 90px; font-size: 13px; color: rgba(242, 90, 90, 1)" v-if="companyCheck === 1">不存在企业</p>
              <p style="position: absolute; top: 28px; left: 90px; font-size: 13px; color: rgba(59, 212, 144, 1)" v-if="companyCheck === 2">检测成功</p>
            </el-form-item>
            <el-form-item>
              <p>姓名</p>
              <el-input
                v-model="perfectCompanyData.contact"
                placeholder="输入姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <p>密码</p>
              <el-input
                v-model="perfectCompanyData.password"
                type="password"
                placeholder="输入密码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <p>确认密码</p>
              <el-input
                v-model="perfectCompanyData.checkPassword"
                type="password"
                placeholder="输入密码"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button class="defaultStyle" @click="logout()">取消</el-button>
            <el-button disabled v-if="companyCheck === 0 || companyCheck === 1" class="cancelStyle">提交</el-button>
            <el-button v-else class="lightDeepStyle" @click="registerSeller('')">提交</el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <!-- 企业认证弹框 -->
    <el-dialog
      class="approveBox"
      title="企业认证"
      :visible.sync="approveDialogVisible"
      width="75%"
      :before-close="handleClose">
        <el-upload
          class="avatar-uploader"
          :action="interAddrss + 'sys/common/upload'"
          :show-file-list="false"
          :data="{uploadType: 'BUSINESS_PRODUCT'}"
          :headers="headers"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove">
          <img v-if="approveData.businessLicense" :src="approveData.businessLicense" class="avatar" style="width: 100%; height: 200px;">
          <span v-if="approveData.businessLicense" class="el-upload-action" @click.stop="handleRemove()">
              <i class="el-icon-delete"></i>
          </span>
          <div v-else style="margin-top: 10px;">
            <i class="el-icon-upload2 avatar-uploader-icon" stop style="font-size: 50px;"></i>
            <p>请上传正确的营业执照</p>
          </div>
          <div class="el-upload__tip" slot="tip">图片大小不能超过5m，支持jpg、jpeg、png、fig、pdf等图片格式</div>
        </el-upload>
        <h3 style="height: 50px;">企业信息</h3>
        <el-row>
        <el-col :span="12" style="padding-right: 20px;">
          <p>企业名称：</p>
          <el-input v-model="approveData.companyName" disabled clearable ></el-input>
        </el-col>
        <el-col :span="12">
          <p>统一社会信用代码：</p>
          <el-input v-model="approveData.xyCode" disabled clearable ></el-input></el-col>
        </el-row>
        <el-row>
        <el-col :span="12" style="padding-right: 20px;">
          <p>住所：</p>
          <el-input v-model="approveData.address" disabled clearable ></el-input>
        </el-col>
        <el-col :span="12">
          <p>法人代表：</p>
          <el-input v-model="approveData.legalPerson" disabled clearable ></el-input>
        </el-col>
        </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approveDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="approveOK">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 已认证弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :show-close="false"
      title="企业认证信息（已认证）"
      :visible.sync="approveDialogVisible2"
      width="30%"
      :before-close="handleClose">
      <el-row>
        <el-col :span="12">企业名称：</el-col>
        <el-col :span="12">{{ approveData.companyName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">统一社会信用代码：</el-col>
        <el-col :span="12">{{ approveData.xyCode }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">住所：</el-col>
        <el-col :span="12">{{ approveData.address }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">法人代表：</el-col>
        <el-col :span="12">{{ approveData.legalPerson }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">认证日期：</el-col>
        <el-col :span="12">{{ approveData.updateTime }}</el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approveDialogVisible2 = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 企业信息弹框 -->
    <el-dialog class="companyInfoDialog" title="企业信息" :visible.sync="companyInfoDialog">
      <div class="companyTitle" slot="title">
        <p @click="chooseInfo = 1" :class="chooseInfo === 1 ? 'chooseInfo' : ''">企业信息</p>
        <p @click="chooseInfo = 2" :class="chooseInfo === 2 ? 'chooseInfo' : ''">税费信息</p>
        <p @click="chooseInfo = 3" :class="chooseInfo === 3 ? 'chooseInfo' : ''">账户信息</p>
      </div>
      <div class="companyContent" v-if="chooseInfo === 1 && showCompanyEdit === false">
        <img v-if="companyInfoDetail.logo == null || companyInfoDetail.logo == ''" src="../assets/img/defaultHead2.svg">
        <img v-else :src="companyInfoDetail.logo">
        <el-button class="editInfoBtn lightDeepStyle" @click="showCompanyEdit = true"><i class="el-icon-edit-outline"></i>编辑</el-button>
        <div class="companyInfoBox"><p><span>企业名称: </span>{{companyInfoDetail.name}}</p><p><span>统一社会信用代码: </span>{{companyInfoDetail.regNumber}}</p></div>
        <div class="companyInfoBox"><p><span>所属行业: </span>{{companyInfoDetail.industryDescribe}}</p><p><span>公司人数：</span>{{companyInfoDetail.staffNumber === null || companyInfoDetail.staffNumber === '' ? '暂无' : companyInfoDetail.staffNumber + '人'}}</p></div>
        <div class="companyInfoBox"><p><span>公司地址：: </span>{{companyInfoDetail.provinceName + companyInfoDetail.cityName + companyInfoDetail.areaName + companyInfoDetail.address}}</p></div>
        <div class="companyInfoBox"><p><span>主要供应产品: </span>{{companyInfoDetail.mainProducts === null || companyInfoDetail.mainProducts === '' ? '暂无' : companyInfoDetail.mainProducts}}</p><p><span>主要采购产品: </span>{{companyInfoDetail.mainBuyProducts === null || companyInfoDetail.mainBuyProducts === '' ? '暂无' : companyInfoDetail.mainBuyProducts}}</p></div>
        <div class="companyInfoBox"><p><span>年收入：</span>{{companyInfoDetail.turnover === null || companyInfoDetail.turnover === '' ? '暂无' : companyInfoDetail.turnover + '万元'}}</p></div>
      </div>
      <div class="companyContent" v-if="chooseInfo === 1 && showCompanyEdit === true">
        <!-- http://api.z-srm.com/ -->
        <!-- http://apisrm.bmxgj.cn/ -->
        <el-upload
          class="avatar-uploader"
          :action="interAddrss + 'sys/common/upload'"
          :show-file-list="false"
          :data="{uploadType: 'BUSINESS_PRODUCT'}"
          :headers="headers"
          :on-success="handleAvatarSuccess2"
          :on-remove="handleRemove2">
          <img v-if="companyInfoDetail.logo" :src="companyInfoDetail.logo" class="avatar">
          <span v-if="companyInfoDetail.logo" class="el-upload-action" @click.stop="handleRemove2()">
              <i class="el-icon-delete"></i>
          </span>
          <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
        </el-upload>
        <div class="companyEditBox">
          <p>
            <span>企业名称: </span>
            <el-input disabled v-model="companyInfoDetail.name" size="mini"></el-input>
          </p>
          <p>
            <span>统一社会信用代码: </span>
            <el-input disabled v-model="companyInfoDetail.regNumber" size="mini"></el-input>
          </p>
        </div>
        <div class="companyEditBox">
          <p>
            <span>所属行业: </span>
            <el-autocomplete class="inline-input" v-model="companyInfoDetail.industryDescribe" :fetch-suggestions="querySearch" placeholder="请输入" @select="handleSelect" size="mini" value-key="name"></el-autocomplete>
          </p>
          <p>
            <span>公司人数：</span>
            <el-input type="number" v-model="companyInfoDetail.staffNumber" placeholder="请输入" size="mini">
              <template slot="suffix">人</template>
            </el-input>
          </p>
        </div>
        <div class="companyEditBox">
          <p>
            <span>公司地址：: </span>
            <el-cascader ref="cascaderRef" :options="provinceList" :props="defaultData" clearable v-model="companyInfoDetail.province" @change="handleChange($event)" size="mini"></el-cascader>
          </p>
          <p>
            <span>详细地址：: </span>
            <el-input type="text" v-model="companyInfoDetail.address"></el-input>
          </p>
        </div>
        <div class="companyEditBox">
          <p>
            <span>主要供应产品: </span>
            <el-input type="text" v-model="companyInfoDetail.mainProducts" size="mini" placeholder="请输入"></el-input>
          </p>
          <p>
            <span>主要采购产品: </span>
            <el-input type="text" v-model="companyInfoDetail.mainBuyProducts" size="mini" placeholder="请输入"></el-input>
          </p>
        </div>
        <div class="companyEditBox">
          <p>
            <span>年收入：</span>
            <el-input type="number"  v-model="companyInfoDetail.turnover" size="mini" placeholder="请输入">
              <template slot="suffix">万元</template>
            </el-input>
          </p>
        </div>
      </div>
      <span v-if="chooseInfo === 1 && showCompanyEdit === true" slot="footer" class="dialog-footer">
        <el-button @click="showCompanyEdit = false">取 消</el-button>
        <el-button type="primary" @click="editEnterInfo()">确 定</el-button>
      </span>
      <div class="companyContent" v-if="chooseInfo === 2">
        <div class="taxRateBox">
          <p>增值税普通发票<span>{{companyInfoDetail.taxRate === '' || companyInfoDetail.taxRate === null ? '未配置' : companyInfoDetail.taxRate}}%</span></p>
          <p @click="openTaxRate(1)" style="cursor: pointer;"><i class="el-icon-edit-outline"></i>编辑</p>
        </div>
        <div class="taxRateBox">
          <p>增值税专用发票<span>{{companyInfoDetail.addTaxRate === '' || companyInfoDetail.addTaxRate === null ? '未配置' : companyInfoDetail.addTaxRate}}%</span></p>
          <p @click="openTaxRate(2)" style="cursor: pointer;"><i class="el-icon-edit-outline"></i>编辑</p>
        </div>
      </div>
      <div class="companyContent" v-if="chooseInfo === 3">
        <div class="taxRateBox">
          <p>开户行<span>{{companyInfoDetail.sysCompanyInvoiceEntity.bank === '' || companyInfoDetail.sysCompanyInvoiceEntity.bank === null ? '未配置' : companyInfoDetail.sysCompanyInvoiceEntity.bank}}</span></p>
          <p @click="openTaxRate(3)" style="cursor: pointer;"><i class="el-icon-edit-outline"></i>编辑</p>
        </div>
        <div class="taxRateBox">
          <p>开户账号<span>{{companyInfoDetail.sysCompanyInvoiceEntity.account === '' || companyInfoDetail.sysCompanyInvoiceEntity.account === null ? '未配置' : companyInfoDetail.sysCompanyInvoiceEntity.account}}</span></p>
          <p @click="openTaxRate(4)" style="cursor: pointer;"><i class="el-icon-edit-outline"></i>编辑</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Base64 } from 'js-base64'
import { logoutApi, getNavMenuApi, sendVerificationApi, changePasswordApi, getUserInfoApi, moreCompanyListApi, updateDefaultCompanyApi, registerSellerApi, loginApi, ocrDeatailApi, getEnterInfoApi, submitApproveAPi, editEnterInfoApi, industryListApi, getProvinceAllApi } from '@/api'
import screenfull from 'screenfull'
export default {
  data() {
    // 部分校验(密码校验)
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 级联选择器分类(地区使用)
      defaultData: {
        value: 'code',
        label: 'name',
        children: 'newChildList',
        expandTrigger: 'hover',
        checkStrictly: true
      },
      // 存储全部省市区列表
      provinceList: [],
      // 显示修改企业信息
      showCompanyEdit: false,
      // 企业信息
      companyInfoDetail: '',
      // 选择的企业信息tab
      chooseInfo: 1,
      // 企业信息弹框
      companyInfoDialog: false,
      // ----------------------------------------
      // 已认证弹框
      approveDialogVisible2: false,
      // 企业认证弹框
      approveDialogVisible: false,
      approveData: {
        id: '',
        businessLicense: '',
        companyName: '',
        xyCode: '',
        address: '',
        legalPerson: '',
        updateTime: ''
      },
      headers: {
        'token': window.$cookies.get('token')
      },
      approveStatus: '',
      // 判断能否切换端
      checkEnd: true,
      // 判断校验状态(0 待校验 1不存在企业 2检测成功)
      companyCheck: 0,
      // 选择端(true采购端 false供应端)
      chooseRole: true,
      // 选中的左侧菜单
      chooseMenu: '工作台',
      // ------------------
      // 表单数据
      ruleForm: {
        password: '',
        checkPassword: '',
        mobile: '',
        code: ''
      },
      // 创建公司传参
      createCompanyData: {
        name: '',
        regNumber: '',
        status: '',
        id: ''
      },
      // 登录框校验
      rules: {
        mobile: [
          { required: false, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '请输入正确格式的手机号码' }
        ],
        password: [
          { required: false, trigger: 'blur' },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/,
            message: '密码长度应为6~16个字符且包含字母和数字'
          },
          { validator: validatePass, trigger: 'blur' }
        ],
        code: [
          { required: false, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^[0-9]{4}$/, message: '请输入四位验证码' }
        ],
        checkPassword: [{ validator: validatePass2, trigger: 'blur' }]
      },
      // 多公司列表
      moreCompanyList: [],
      // 控制验证码按钮的禁用状态
      controlBtn: false,
      // 控制侧边栏的显示和隐藏
      isCollapse: false,
      // 左侧导航菜单
      navMenu: [],
      // 控制是否弹出修改密码框
      dialogVisible: false,
      // 控制是否弹出创建新公司框
      createNewCompanyDialog: false,
      // 控制是否弹出用户服务协议
      dialogUserAccess: false,
      // 控制是否弹出完善企业信息
      perfectCompanyDialog: false,
      // 完善企业信息数据
      perfectCompanyData: {
        licensePic: '',
        mainProducts: '',
        modes: '',
        industry: '',
        roleType: window.sessionStorage.getItem('roleType'),
        regNumber: '',
        name: '',
        contact: '',
        username: '',
        password: '',
        checkPassword: '',
        code: '',
        type: ''
      },
      // 是否勾选同意协议按钮
      agreeChooseBtn: false,
      // 控制是否全屏
      isFullscreen: false,
      // 公司名称
      company: '',
      // 公司id,
      companyId: null,
      userLevel: '',
      name: '',
      // 头像地址
      imgAdress: '',
      // 判断是否是离职状态
      quitStatus: window.sessionStorage.getItem('quitValue'),
      // 判断当前是创建公司,还是重新提交申请
      controlAddCompany: true,
      // 获取接口地址
      interAddrss: window.sessionStorage.getItem('interAddrss')
    }
  },
  created() {
    if (window.$cookies.get('newToken') === 'default') {
      this.perfectCompanyData.username = this.$route.query.username
      this.perfectCompanyData.code = this.$route.query.code
      this.perfectCompanyDialog = true
    }
    if (window.sessionStorage.getItem('roleType') == null) {
      window.sessionStorage.setItem('roleType', this.chooseRole === true ? '1' : '2')
    }
    this.approveData.id = JSON.parse(window.$cookies.get('companyId'))
    this.companyId = JSON.parse(window.$cookies.get('companyId'))
    this.chooseRole = window.sessionStorage.getItem('roleType') === '1'
    this.getNavMenu()
    this.getUserInfo().then(() => {
      this.approveStatus = JSON.parse(sessionStorage.getItem('userInfo')).status
    })
    this.getMoreCompanyList()
  },
  mounted() {
    window.onresize = () => {
      // 全屏下监控是否按键了ESC
      this.isFullscreen = !this.isFullscreen
    }
    // 'http://apisrm.bmxgj.cn/'  测试服
    // 'https://api.z-srm.com/'    正式服
    // 全局存一个接口地址
    window.sessionStorage.setItem('interAddrss', 'http://apisrm.bmxgj.cn/')
    // window.sessionStorage.setItem('interAddrss', 'https://newapi.z-srm.com/')
  },
  methods: {
    // 移除图片
    handleRemove2() {
      this.companyInfoDetail.logo = ''
    },
    // 上传成功回调
    async handleAvatarSuccess2(res, file) {
      this.companyInfoDetail.logo = res.data.url
    },
    // 级联选择器改变触发
    handleChange(e) {
      let addressName = this.$refs.cascaderRef.getCheckedNodes()[0].pathLabels
      this.companyInfoDetail.provinceCode = e[0]
      this.companyInfoDetail.provinceName = addressName[0]
      if (e[1] != undefined) {
        this.companyInfoDetail.cityCode = e[1]
        this.companyInfoDetail.cityName = addressName[1]
      } else {
        this.companyInfoDetail.cityCode = ''
        this.companyInfoDetail.cityName = ''
      }
      if (e[2] != undefined) {
        this.companyInfoDetail.areaCode = e[2]
        this.companyInfoDetail.areaName = addressName[2]
      } else {
        this.companyInfoDetail.areaCode = ''
        this.companyInfoDetail.areaName = ''
      }
      this.$refs.cascaderRef.dropDownVisible = false
    },
    // 获取省市区信息
    async getProvince() {
      const { data: res } = await getProvinceAllApi()
      if (res.code !== 0) return this.$message.error('获取省份失败!')
      this.provinceList = res.data.list
      this.provinceList.forEach((item, index) => {
        if (item.childList.length !== 0) {
          item.newChildList = item.childList
          item.newChildList.forEach((items, index) => {
            if (items.childList.length !== 0) {
              items.newChildList = items.childList
            }
          })
        }
      })
    },
    // 获取行业表信息
    async getIndustryList() {
      const { data: res } = await industryListApi()
      if (res.code !== 0) return this.$message.error('获取行业类型失败!')
      this.industryList = res.data.list
      if (this.industryList.industry === 0) {
        this.industryList.industry = ''
      }
    },
    handleSelect(company) {
      this.companyInfoDetail.industry = company.id
      this.companyInfoDetail.industryDescribe = company.name
    },
    // 输入框查询行业表信息功能
    querySearch(queryString, cb) {
      let industryList = this.industryList
      let results = queryString ? industryList.filter(this.createFilter(queryString)) : industryList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    // 修改企业信息
    async editEnterInfo() {
      const { data: res } = await editEnterInfoApi(this.companyInfoDetail)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success({ duration: 1000, message: '修改成功!' })
      this.showCompanyEdit = false
      this.getEnterInfo()
    },
    // 打开编辑弹框
    openTaxRate(val) {
      if (val === 1) {
        this.$prompt('<span style="color: red;">*</span>增值税普通发票: ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          inputValue: this.companyInfoDetail.taxRate,
          inputPattern: /^(?!0{2,})\d{0,5}(?:\.\d{1,3})?$/,
          inputErrorMessage: '增值税格式不正确'
        }).then(({ value }) => {
          this.companyInfoDetail.taxRate = value
          this.editEnterInfo()
        })
      } else if (val === 2) {
        this.$prompt('<span style="color: red;">*</span>增值税专用发票: ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          inputValue: this.companyInfoDetail.addTaxRate,
          inputPattern: /^(?!0{2,})\d{0,5}(?:\.\d{1,3})?$/,
          inputErrorMessage: '增值税格式不正确'
        }).then(({ value }) => {
          this.companyInfoDetail.addTaxRate = value
          this.editEnterInfo()
        })
      } else if (val === 3) {
        this.$prompt('<span style="color: red;">*</span>开户行: ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          inputValue: this.companyInfoDetail.sysCompanyInvoiceEntity.bank,
          inputPattern: /^[\u4e00-\u9fa5]{1,10}$/,
          inputErrorMessage: '开户行不能为空且限制10个字'
        }).then(({ value }) => {
          this.companyInfoDetail.sysCompanyInvoiceEntity.bank = value
          this.editEnterInfo()
        })
      } else if (val === 4) {
        this.$prompt('<span style="color: red;">*</span>开户账号: ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          inputValue: this.companyInfoDetail.sysCompanyInvoiceEntity.account,
          inputPattern: /^\d{1,30}$/,
          inputErrorMessage: '开户账号不能为空且限制30个数字'
        }).then(({ value }) => {
          this.companyInfoDetail.sysCompanyInvoiceEntity.account = value
          this.editEnterInfo()
        })
      }
      
    },
    // 获取企业信息
    async getEnterInfo() {
      const { data: res } = await getEnterInfoApi({ companyId: this.companyId })
      let newCompanyInfoDetail = res.data.companyInfo
      this.companyInfoDetail = JSON.parse(JSON.stringify(newCompanyInfoDetail))
      this.companyInfoDetail.province = [newCompanyInfoDetail.provinceCode, newCompanyInfoDetail.cityCode, newCompanyInfoDetail.areaCode]
    },
    // -------------------------
    // 上传图片
    // 移除图片
    handleRemove() {
      this.approveData.businessLicense = ''
      this.approveData.companyName = ''
      this.approveData.xyCode = ''
      this.approveData.address = ''
      this.approveData.legalPerson = ''
    },
    // 上传成功回调
    async handleAvatarSuccess(res, file) {
      this.approveData.businessLicense = res.data.url
      const { data: result } = await ocrDeatailApi(this.approveData.businessLicense)
      this.approveData.companyName = result.data.name
      this.approveData.xyCode = result.data.regNum
      this.approveData.address = result.data.address
      this.approveData.legalPerson = result.data.person
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const type = file.type === 'image/jpeg' || 'image/jpg' || 'image/fig' || 'image/png' || 'image/pdf'
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，fig，JPEG,pdf)')
      }
      if (!isLt2M) {
        this.$message.error('}, 200),大小不能超过5MB!')
      }
      return type && isLt2M
    },
    // 注册成功供应商
    async registerSeller(val) {
      if (this.perfectCompanyData.name === '') return this.$message.error('公司名称不能为空!')
      if (val === '') {
        if (this.perfectCompanyData.contact === '') return this.$message.error('姓名不能为空!')
        if (this.perfectCompanyData.password === '') return this.$message.error('密码不能为空!')
        if (this.perfectCompanyData.password !== this.perfectCompanyData.checkPassword) return this.$message.error('两次密码输入不一致!')
      }
      this.perfectCompanyData.type = val
      const { data: res } = await registerSellerApi(this.perfectCompanyData)
      if (this.perfectCompanyData.type === 1) {
        if (res.code === -1) {
          this.companyCheck = 1
          this.$message.error(res.msg)
        } else {
          this.companyCheck = 2
        }
        return false
      }
      if (val === '') {
        setTimeout(async() => {
          if (res.code !== 0) return this.$message.error(res.msg)
          // 然后去登录
          // 登录校验及调用密码登录接口
          let encryPassword = 'hAw6eqnFLKxpsDv3' + Base64.encode(this.perfectCompanyData.password)
          const { data: ress } = await loginApi({
            username: this.perfectCompanyData.username,
            password: encryPassword
          })
          if (ress.code === 504) {
            this.$message.error(res.msg)
          }
          if (res.data.user.companyId === 0) return this.$message.error('该账户已被禁用,请联系管理员!')
          if (ress.code === -1) return this.$message.error(res.msg)
          // 设置cookies保存时间
          var date = new Date()
          date.setDate(date.getDate() + 15)
          // 存储企业id
          window.$cookies.set('companyId', JSON.stringify(res.data.user.companyId))
          // 存储企业号
          window.sessionStorage.setItem('companyNum', ress.data.companyNum)
          window.sessionStorage.setItem('workStatus', ress.data.workStatus)
          // 设置token并将token有效期设置为7天
          this.$cookies.set('token', ress.data.token, { expires: 7 })
          // 存储当前选中的是采购端还是供应端
          // window.sessionStorage.setItem('roleType', 2)
          this.$message.success('完善成功!')
          const { data: resss } = await updateDefaultCompanyApi(res.data.user.companyId)
          console.log(resss)
          this.$cookies.remove('newToken')
          this.$router.go(0)
        }, 1000)
      }
    },
    // 切换端
    changeRole() {
      // 判断能否切换端
      if (this.checkEnd === false) {
        this.$message.error('当前账户并未配置菜单权限,无法切换!(如已配置,请刷新后重试)')
        return false
      }
      this.checkEnd = false
      this.chooseRole = !this.chooseRole
      this.chooseCompanyId = ''
      window.sessionStorage.setItem('roleType', this.chooseRole === true ? '1' : '2')
      this.$router.replace({ path: '/index' })
      setTimeout(() => {
        this.getNavMenu()
        this.$router.go(0)
      }, 250)
    },
    // 切换公司
    async updateDefaultCompany(obj) {
      if (obj.companyId === JSON.parse(window.sessionStorage.getItem('userInfo')).companyId) {
        return false
      }
      if (obj.status === 0) return this.$message.error('该公司等待平台审核中,暂时无法切换,如有疑问请联系管理人员')
      if (obj.status === 3) {
        this.$confirm('该公司平台审核未通过,是否重新提交申请', '提示', {
          confirmButtonText: '重新申请',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.controlAddCompany = false
          this.agreeChooseBtn = false
          this.createCompanyData.id = obj.companyId
          this.createCompanyData.name = obj.companyName
          this.createCompanyData.regNumber = obj.regNumber
          this.createCompanyData.status = 0
          this.createNewCompanyDialog = true
        }).catch(() => {
        })
        return false
      }
      if (obj.expStatus === 0) {
        this.$message.error('所选企业会员已到期，暂时无法使用，如有疑问请联系平台管理人员。')
        return false
      }
      const { data: res } = await updateDefaultCompanyApi(obj.companyId)
      if (res.code !== 0) return this.$message.error('切换失败!')
      window.$cookies.set('companyId', obj.companyId)
      this.companyId = obj.companyId
      // 全局刷新
      window.sessionStorage.removeItem('editableTabs')
      window.sessionStorage.removeItem('editableTabsValue')
      this.$router.push('/index')
      this.$router.go(0)
    },
    // 获取多公司列表
    async getMoreCompanyList() {
      // window.sessionStorage.getItem('roleType')
      const { data: res } = await moreCompanyListApi({ type: 1, roleType: '' })
      this.moreCompanyList = res.data.list
    },
    // 路由跳转
    routerPush(url) {
      this.$router.push(url)
    },
    // 获取用户的个人信息
    async getUserInfo() {
      if (this.companyId === null) {
        window.$cookies.remove('token')
        window.sessionStorage.removeItem('userInfo')
        window.$cookies.remove('buyId')
        window.$cookies.remove('companyId')
        window.sessionStorage.removeItem('companyNum')
        window.sessionStorage.removeItem('editableTabs')
        window.sessionStorage.removeItem('editableTabsValue')
        this.$cookies.remove('auto')
        this.$router.replace('/login')
      }
      const { data: res } = await getUserInfoApi({ companyId: this.companyId, buyId: 0, userId: '' })
      window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
      this.ruleForm.mobile = res.data.userInfo.mobile
      this.company = res.data.userInfo.company
      this.name = res.data.userInfo.name
      this.imgAdress = res.data.userInfo.portrait
      this.userLevel = res.data.userInfo.levelName
    },
    helpCenterJump(val) {
      if (val === 1) {
        window.open('https://www.z-srm.com/#/commonProblem')
      } else {
        window.open('https://www.z-srm.com/#/index')
      }
    },
    openCreateNewCompany() {
      this.createCompanyData.name = ''
      this.createCompanyData.regNumber = ''
      this.createCompanyData.id = ''
      this.createCompanyData.status = ''
      this.controlAddCompany = true
      this.agreeChooseBtn = false
      this.createNewCompanyDialog = true
    },
    // 不同意勾选用户协议
    disAgree() {
      this.agreeChooseBtn = false
      this.dialogUserAccess = false
    },
    // 同意勾选用户协议
    agree() {
      this.agreeChooseBtn = true
      this.dialogUserAccess = false
    },
    // 标签页跳转
    routerJump(url, query, index) {
      if (url === 'dataUphold') {
        this.getEnterInfo()
        this.getIndustryList()
        this.getProvince()
        // 如果是打开企业信息,则展示弹框
        this.companyInfoDialog = true
      } else {
        let newQuery = []
        if (query.length !== 0) {
          query.forEach(item => {
            newQuery.push(item.name)
          })
        }
        window.sessionStorage.setItem('btnLimit', JSON.stringify(newQuery))
        this.$router.push({ path: '/' + url })
        this.chooseMenu = index
      }
    },
    // 隐藏侧边栏
    hiddenAside() {
      this.isCollapse = !this.isCollapse
    },
    // 刷新左侧菜单
    updateMenu() {
      this.getNavMenu()
    },
    // 退出登录状态
    async logout() {
      const { data: res } = await logoutApi()
      if (res.code !== 0) return this.$message.error('退出登录失败!')
      // window.sessionStorage.removeItem('token')
      this.$cookies.remove('token')
      window.sessionStorage.removeItem('userInfo')
      this.$cookies.remove('buyId')
      this.$cookies.remove('company')
      this.$cookies.remove('companyId')
      window.sessionStorage.removeItem('companyNum')
      window.sessionStorage.removeItem('quitValue')
      this.$cookies.remove('newToken')
      this.$cookies.remove('auto')
      this.$router.push('/login')
    },
    // 获取左侧导航菜单
    async getNavMenu() {
      const { data: res } = await getNavMenuApi(window.sessionStorage.getItem('roleType'))
      if (res.code === -11) {
        this.$router.replace('/login')
        return false
      }
      if (res.data.menuList.length === 0) {
        this.$router.replace('/login')
        this.$message.error('当前账户并未配置菜单权限,强制回到登录界面!')
        return false
      }
      window.sessionStorage.setItem('menuIdList', JSON.stringify(res.data.menuList))
      let arr = res.data.menuList
      for (var i = 0; i < arr.length; i++) {
        let newArr = arr[i].list
        for (var j = 0; j < newArr.length; j++) {
          if (newArr[j].list.length === 0) {
            newArr.splice(j, 1)
            j--
          }
        }
      }
      for (var k = 0; k < arr.length; k++) {
        if (arr[k].list.length === 0) {
          arr.splice(k, 1)
          k--
        }
      }
      this.navMenu = arr
      // 重新排序
      this.navMenu[0].list = this.navMenu[0].list.sort((a, b) => {
        return a.orderNum - b.orderNum
      })
      // 获取另一方的左侧菜单,如果列表没值,就控制不让跳转
      const { data: ress } = await getNavMenuApi(window.sessionStorage.getItem('roleType') === 1 ? 2 : 1)
      if (ress.data.menuList[0].list.length === 0) {
        // 阻止切换
        this.checkEnd = false
      }
    },
    // 发送验证码
    sendVerification(formName) {
      // 校验手机号格式是否正确
      this.$refs[formName].validateField('mobile', async mobileError => {
        if (!mobileError) {
          const { data: res } = await sendVerificationApi({
            type: 1,
            mobile: this.ruleForm.mobile
          })
          if (res.code !== 0) return this.$message.error('发送验证码失败!')
          this.$message.success({ duration: 1000, message: '验证码发送成功!' })
          // 获取验证码按钮元素(实现验证码倒计时功能)
          var sendBtn = this.$refs.sendVeri.$el
          let num = 30
          var that = this
          let timer = setInterval(function() {
            num--
            that.controlBtn = true
            sendBtn.innerHTML = num + '秒后重新获取'
            sendBtn.style.color = '#ccc'
            if (num === 0) {
              that.controlBtn = false
              sendBtn.style.color = '#fff'
              sendBtn.innerHTML = '发送验证码'
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$message.error('请填入正确的手机号')
          return false
        }
      })
    },
    // 修改密码功能
    async changePassword() {
      if (this.ruleForm.checkPassword !== this.ruleForm.password) { return this.$message.error('两次输入密码不一致!') }
      let editRuleForm = {
        password: 'hAw6eqnFLKxpsDv3' + Base64.encode(this.ruleForm.password),
        checkPassword:
          'hAw6eqnFLKxpsDv3' + Base64.encode(this.ruleForm.checkPassword),
        mobile: this.ruleForm.mobile,
        code: this.ruleForm.code
      }
      const { data: res } = await changePasswordApi(editRuleForm)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message({
        type: 'success',
        duration: 1000,
        message: '修改密码成功!'
      })
      var sendBtn = this.$refs.sendVeri.$el
      this.controlBtn = false
      sendBtn.style.color = '#ffa600'
      sendBtn.innerHTML = '发送验证码'
      clearInterval(this.timer)
      this.ruleForm.password = ''
      this.ruleForm.code = ''
      this.ruleForm.checkPassword = ''
      this.dialogVisible = false
    },
    // 全屏模式
    screenfull() {
      if (screenfull.enabled) {
        this.$message({
          message: '您的浏览器无法进入全屏模式',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    // 企业认证
    async approve() {
      //  1未认证 2已认证 3驳回
      if (this.approveStatus === 1 || this.approveStatus === 3) {
        this.approveDialogVisible = true
      } else {
        const { data: res } = await getEnterInfoApi({ id: this.approveData.id })
        this.approveData.companyName = res.data.companyInfo.name
        this.approveData.xyCode = res.data.companyInfo.businessNo
        this.approveData.address = res.data.companyInfo.address
        this.approveData.legalPerson = res.data.companyInfo.legalPerson
        this.approveData.updateTime = res.data.companyInfo.updateTime
        console.log(this.approveData)
        this.approveDialogVisible2 = true
      }
    },
    // 关闭认证弹框
    handleClose() {
      this.approveData.address = ''
      this.approveData.businessLicense = ''
      this.approveData.companyName = ''
      this.approveData.legalPerson = ''
      this.approveData.xyCode = ''
      this.approveDialogVisible = false
    },
    async approveOK() {
      const { data: res } = await submitApproveAPi(this.approveData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message({
        type: 'success',
        duration: 1000,
        message: '提交成功!'
      })
      this.approveDialogVisible = false
    }
  }
}
</script>
<style scoped lang='less'>
.home_contaner {
  display: flex;
  height: 100%;
  // min-width: 1200px;
}
// 侧边栏
.el-aside {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  background-color: white;
  overflow: visible;
  z-index: 10;
  .logo {
    height: 60px;
    width: 100%;
    background-color: white;
    color: black;
    font-size: 19px;
    line-height: 60px;
    text-align: center;
    img {
      margin-right: 6px;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      cursor: pointer;
    }
    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      color: black;
      cursor: pointer;
    }
  }
  .asideBox {
    padding-bottom: 20px;
    width: 100%;
    height: calc(100vh - 60px);
    box-sizing: border-box;
    overflow-y: auto;
    li {
      p {
        margin: 20px 0 10px;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 12px;
        cursor: default;
      }
      span {
        display: block;
        padding-left: 20px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.60);
        cursor: pointer;
        i {
          margin-right: 10px;
        }
        u {
          float: right;
          margin-top: 5px;
          width: 2px;
          height: 20px;
          background-color: rgba(18, 62, 131, 1);
        }
      }
      .highlight {
        color: rgba(18, 62, 131, 1);
        font-weight: bold;
      }
    }
  }
  /deep/ ::-webkit-scrollbar {
    width: 0!important;
  }
}
// 内容区
.el-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f6fa;
}
.el-upload {
    width: 100%;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    outline: 0;
}
// 内容区头部
.el-header {
  position: relative;
  height: 60px !important;
  background-color: #fff;
  line-height: 60px;
  color: #000;
  font-weight: 300;
  vertical-align: middle;
  a {
    display: inline-block;
    margin: 0 26px;
    width: 20px;
    height: 42px;
    line-height: 42px;
    color: #000;
    cursor: pointer;
    vertical-align: middle;
    i {
      font-size: 18px;
      color: rgba(18, 62, 131, 1);
    }
  }
  i {
    margin-right: 10px;
    font-size: 18px;
    color: #aeafb4;
  }
}
.el-header >span {
  display: inline-block;
  margin-left: 20px;
  font-weight: 300;
}
.el-header .el-button {
  width: 38px;
  height: 38px;
  font-size: 18px;
  border: 0px;
  vertical-align: middle;
  i {
    margin-left: -2px;
    color: #c8cad4;
  }
}
.el-header .setBtn {
  margin-right: 10px;
}
.el-header .messageBtn {
  margin-right: 10px;
  i {
    color: rgba(18, 62, 131, 1);
  }
}
.el_header_right {
  position: absolute;
  right: 0;
  display: inline-block;
  height: 100%;
  font-size: 14px;
  .loginChangeBox {
    position: relative;
    display: inline-block;
    margin: 8px 20px;
    width: 160px;
    height: 32px;
    border-radius: 32px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    line-height: 32px;
    font-size: 14px;
    p {
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      background-color: #fff;
      border-radius: 32px;
      background-color: rgba(18, 62, 131, 1);
      color: #fff;
      text-align: center;
      transition: all .3s ease-out;
    }
    .chooseBox {
      left: 50%;
      background-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
    span {
      display: inline-block;
      width: 80px;
      color: rgba(0, 0, 0, 0.60);
      text-align: center;
    }
  }
  .helpCenter {
    margin-right: 20px;
    cursor: pointer;
  }
  .helpCenter:hover {
    color: #1bc7b5;
  }
}
// 头部下拉列表
.el-header {
  display: flex;
  align-items: center;
  height: 60px;
  .iSpan {
    padding: 0 5px;
    width: 50px;
    height: 20px;
    font-size: 12px;
    color: white;
    text-align: center;
    line-height: 20px;
    background: linear-gradient(225deg, #314059 0%, #293547 100%);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }
}
.el-header .el-dropdown {
  height: 60px;
  vertical-align: middle;
}
.el-header .el-dropdown-link {
  margin-left: 50px;
  display: inline-block;
  cursor: pointer;
  span {
    margin: 0 11px;
    font-size: 16px;
    color: #000;
    font-weight: 300;
  }
  i {
    color: #aeafb4;
  }
  img {
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}
.el-header .company-link {
  margin-left: -12px !important;
}
.el_form_item_shortMsg .el-input {
  width: 173px !important;
}
.el_form_item_shortMsg .el-button {
  position: absolute;
  padding: 12px 0;
  right: 10px;
  width: 30%;
  text-align: center;
}
.el-main {
  flex: 1;
  position: relative;
  background-color: #f5f6fa;
  padding: 0;
  .el-tabs {
    margin: 6px 0;
    height: 25px;
    .el-tabs__item {
      position: relative;
      span {
        display: inline-block;
        padding: 0 0 0 15px;
        height: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
      }
      .el-dropdown {
        position: absolute;
        top: 0;
        right: -42px;
        background-color: rgba(225, 226, 229, 1);
      }
    }
    /deep/ .el-tabs__item .el-icon-close {
      border-radius: 0;
      background-color: #e1e2e5;
      transition: 0ms;
    }
    /deep/ .el-tabs__nav {
      padding-right: 40px;
    }
  }
}
.el-main .el-tabs:nth-child(1) .el-tabs__item:nth-child(1) span {
  padding: 0 20px;
}
// 导航条下拉列表
.el-main .el-dropdown {
  padding: 0;
  height: 25px;
  width: 40px;
  color: #000;
  background-color: #e1e2e5;
  .el-dropdown-link {
    padding: 2px 10px !important;
  }
}
.el-main .el-dropdown:hover .el-dropdown-link .el-icon-caret-bottom {
  color: #000;
}
.el-main .el-dropdown i {
  font-size: 20px;
}
.quitBox {
  width: 100%;
  height: 100%;
  .el-button {
    margin-top: 20%;
    margin-left: 50%;
    width: 200px;
    height: 60px;
    transform: translate(-50%);
    text-align: center;
    font-size: 16px;
    background-color: #2b2b2b;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #2b2b2b;
  }
  .el-button:hover {
    color: rgba(18, 62, 131, 1);
    background-color: rgba(43, 43, 43, 0.75);
  }
}
.el-dropdown-menu li:hover {
  font-weight: bold;
  color: rgba(18, 62, 131, 1);
  background-color: #f8f8fb;
}
.el-footer {
  display: flex;
  height: 64px !important;
  width: 100%;
  background-color: #383a42;
}
.footer_left {
  height: 100%;
  width: 75%;
  line-height: 64px;
  color: #fff;
}
.footer_left a {
  color: #fff;
}
.footer_right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 25%;
}
.footer_right > a {
  flex: 1;
  display: flex;
  margin: 5px 10px;
  flex-direction: column;
  align-items: center;
  width: 100px;
}
.footer_right > a i {
  font-size: 28px;
  color: #959595;
}
.footer_right > a:nth-child(1) i {
  color: #86c610;
}
.footer_right > a p {
  white-space: nowrap;
  color: #fff;
}
/* 勾选按钮 */
.el-form .el_form_agree {
  margin: -18px 5px;
}
.el-form .el_form_agree span {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
}
.el-form .el_form_agree span .el-button {
  position: absolute;
  margin: 0 5px;
  width: 112px;
}
// 用户服务协议内容
.userAgreement {
  .el-dialog {
    h2 {
      height: 18px;
      margin-bottom: 10px;
      color: #000;
      font-weight: 400;
    }
    p {
      margin: 15px 5px;
      line-height: 25px;
      font-weight: 400;
      color: #737373;
    }
    .el-dialog__body p {
      white-space: normal;
    }
  }
}
.el-dialog__headerbtn {
  top: 12px;
}
.createNewCompany {
  /deep/.el-dialog {
    height: 277px;
  }
}
/deep/.el-icon-arrow-down:before {
  color: #000 !important;
}
.el-submenu .el-submenu__title i {
  color: black !important;
}
.el-breadcrumb {
  margin-left: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.el-menu-item-group .menu_seconed {
  background-color: white !important;
}
.el-menu-item-group span {
  margin: 0 6px;
  font-size: 14px;
  color: black;
}
.el-menu-item-group .menu_seconed > i {
  color: black !important;
}
.el-breadcrumb {
  margin-top: 25px;
  margin-bottom: 30px;
}
// 完善企业信息弹框
.perfectCompanyStyle {
  margin-top: 15vh;
  /deep/.el-dialog {
    height: 339px;
    width: 424px;
    .el-dialog__header {
      padding: 20px 20px 0px;
      .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.88);
      }
      .el-dialog__headerbtn {
        top: 20px;
        .el-icon-close:before {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
    .el-dialog__body {
      height: 220px;
      .el-form-item {
        margin: 0;
        .el-form-item__content {
          margin: 20px 0 0;
          display: flex;
          justify-content: space-between;
          p {
            height: 32px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.80);
            text-align: left;
          }
        }
      }
    }
  }
}
// 企业信息弹框
.companyInfoDialog {
  /deep/.el-dialog {
    width: 828px;
    height: auto;
    .el-dialog__header {
      padding: 20px 20px 0px;
      .companyTitle {
        display: flex;
        p {
          margin-right: 20px;
          height: 35px;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
        }
        .chooseInfo {
          border-bottom: 2px solid rgba(18, 62, 131, 1);
          font-weight: bold;
        }
      }
      .el-dialog__headerbtn {
        top: 20px;
        .el-icon-close:before {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
    .el-dialog__body {
      height: auto;
      .companyContent {
        position: relative;
        margin: 20px 0;
        min-height: 370px;
        font-weight: 500;
        img {
          width: 140px;
          height: 140px;
          border-radius: 16px;
        }
        .editInfoBtn {
          position: absolute;
          top: 0;
          right: 0;
          i {
            margin-right: 5px;
          }
        }
        .companyInfoBox {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          p {
            flex: 1;
            font-size: 14px;
            line-height: 24px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.80);
            span {
              color: rgba(0, 0, 0, 0.40);
            }
          }
        }
        .avatar-uploader{
          width: 140PX;
          height: 140PX;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          background: url('../assets/img/defaultHead2.svg') no-repeat;
          background-size: 100% 100%;
          border-radius: 16px;
          vertical-align: top;
        }
        .avatar-uploader-icon{
          font-size: 0;
          color: #fff;
          width: 140PX;
          height: 140PX;
          line-height: 140PX;
          text-align: center;
        }
        .avatar-uploader-icon:hover{
          font-size: 28px;
          background-color: rgba(0, 0, 0, .3);
        }
        .avatar {
          position: relative;
          width: 140PX;
          height: 140PX;
          display: block;
        }
        .el-upload-action {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 140PX;
          height: 140PX;
          font-size: 0;
          color: #fff;
          text-align: center;
          line-height: 140PX;
        }
        .el-upload-action:hover {
          font-size: 20px;
          background-color: #000;
          background-color: rgba(0, 0, 0, .3)
        }
        .companyEditBox {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          p {
            width: calc(50% - 10px);
            font-size: 14px;
            line-height: 24px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.80);
            span {
              display: block;
              color: rgba(0, 0, 0, 0.40);
            }
            .el-input {
              margin-top: 10px;
              width: 100%;
            }
            .el-autocomplete {
              width: 100%;
            }
            .el-cascader {
              width: 100%;
            }
          }
        }
        .taxRateBox {
          margin-bottom: 20px;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 48px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 1px solid rgba(0,0,0,0.15);
          line-height: 48px;
          p {
            color: rgba(0, 0, 0, 0.80);
            font-size: 14px;
            font-weight: bold;
            span {
              margin-left: 50px;
            }
            i {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
