// 采购单列表
<template>
  <div>
    <div class="purchasePlan">
      <h5>采购单</h5>
      <nav>
        <div class="navLeft">
          <p><span>采购订单数量</span><span style="color: rgba(18, 62, 131, 1)">今日+{{indexTitleData[3].dayCount}}</span><i>{{indexTitleData[3].allCount}}</i></p>
          <div id="mains"></div>
        </div>
        <div class="navRight">
          <div class="navRightHeader">
            <div>
              <span @click="switchMyBox(1)" :class="choosePlan === 1 ? 'chooseMy' : ''">当前计划</span>
              <span @click="switchMyBox(2)" :class="choosePlan === 2 ? 'chooseMy' : ''">一周内计划</span>
              <span @click="switchMyBox(3)" :class="choosePlan === 3 ? 'chooseMy' : ''">本月计划</span>
              <span @click="switchMyBox(4)" :class="choosePlan === 4 ? 'chooseMy' : ''">{{new Date().getMonth() + 2}}月计划</span>
              <span @click="switchMyBox(5)" :class="choosePlan === 5 ? 'chooseMy' : ''">{{new Date().getMonth() + 3}}月计划</span>
              <span @click="switchMyBox(6)" :class="choosePlan === 6 ? 'chooseMy' : ''">{{'更多计划>>'}}</span>
            </div>
            <el-button class="lightDeepStyle" @click="openTurnOrder()"><i style="margin-right: 4px;" class="iconfont icon-change"></i>转订单</el-button>
          </div>
          <div class="navRightContent">
            <el-table v-table-scroll="handleScroll" ref="tableHeight2" :height="160" :data="purchasePlanList" style="width: 100%;">
              <el-table-column type="test" align="center" width="55" fixed="left">
                <template slot="header" slot-scope="scope">
                  <el-checkbox v-model="chooseAllList" @change="handleChooseAllList"></el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-if="typeof(scope.row.choose) === 'boolean'" v-model="scope.row.choose" @change="handleChoosePartList"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="code" label="计划单编码" min-width="120px" align="center" fixed="left">
                <template slot-scope="scope">
                  <div>
                    <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(4, scope.row.id)">{{scope.row.code}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px"  align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.productName}}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="number" label="计划采购数量" min-width="80px"  align="center"></el-table-column>
              <el-table-column show-overflow-tooltip label="采购进度" min-width="160px"  align="center">
                <template slot-scope="scope">
                  <el-progress style="vertical-align: middle;" color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="((scope.row.number - scope.row.residueNumber) / scope.row.number * 100)"></el-progress>
                  <span style="margin: 0 10px;">{{((scope.row.number - scope.row.residueNumber) / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="buyTime" label="计划开始日期" min-width="110px"  align="center">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.buyTime">{{(scope.row.buyTime).substring(0, 10)}}</span>
                    <span v-else>-</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column key="7" show-overflow-tooltip label="操作" min-width="80px"  align="center">
                <template slot-scope="scope">
                  <span @click="routerJump(4, scope.row.id)" class="controlBox"><i class="el-icon-warning-outline"></i>查看</span>
                </template>
              </el-table-column>
              <div slot="empty" class="emptyBg">
                <span style="display: block; margin-top: 0%">暂无数据</span>
              </div>
            </el-table>
          </div>
        </div>
      </nav>
    </div>
    <!-- 转订单弹框 -->
    <el-dialog class="chooseOrderDialog" title="选择计划单中的产品" :visible.sync="turnOrderDialog" :close-on-click-modal="false" v-dialogDrag>
      <div>
        <el-input class="sectionInp" v-model="matterData.selectAll" placeholder="计划单编号/产品编号/产品名称搜索" @input="getPlanOrderList()" prefix-icon="el-icon-search"></el-input>
      </div>
      <el-table ref="matterSearchRef" :data="matterList" style="width: 100%" max-height="276px" @row-click="handleMatterSelectionChangeRow" @select="handleMatterSelectionChange" @select-all="allMatterChoose">
        <el-table-column type="selection" width="45px" align="center" fixed="left"></el-table-column>
        <el-table-column show-overflow-tooltip prop="planCode" label="计划单编号" min-width="110px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productCode" label="产品编号" min-width="110px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="120px" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip prop="productSpecs" label="规格" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productSpecs === '' || scope.row.productSpecs === null ? '暂未填写' : scope.row.productSpecs}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productNewColour" label="颜色" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productNewColour === '' || scope.row.productNewColour === null ? '暂未填写' : scope.row.productNewColour}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="productType" label="类型" min-width="80px" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.productTypeName === '' || scope.row.productTypeName === null ? '暂未填写' : scope.row.productTypeName}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="unitName" label="单位" min-width="130px" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button class="defaultStyle cancleBtn" @click="turnOrderDialog = false">取 消</el-button>
        <el-button class="lightDeepStyle" @click="confirmChoose()">确 认</el-button>
      </div>
    </el-dialog>
    <div class="purchaseOrder">
      <section>
        <el-input class="sectionInp" v-model.trim="purchaseOrderData.code" placeholder="编号搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
        <el-input class="sectionInp" v-model.trim="purchaseOrderData.productName" placeholder="产品名称搜索" @input="getpurchaseOrderList()" prefix-icon="el-icon-search"></el-input>
        <!-- 审核日期 -->
        <div class="block">
          <el-date-picker
            v-model="chooseTime"
            @change="getTimess"
            type="daterange"
            range-separator="至"
            start-placeholder="申请日期区间"
            end-placeholder="申请日期区间"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-select v-if="active === 1" @change="getpurchaseOrderList()" v-model="purchaseOrderData.state" placeholder="订单状态筛选" size="mini" filterable clearable>
          <el-option label="待确认" :value="7"></el-option>
          <!-- <el-option label="待发货" :value="0"></el-option>
          <el-option label="部分发货" :value="8"></el-option>
          <el-option label="待收货" :value="9"></el-option>
          <el-option label="部分收货" :value="10"></el-option> -->
          <el-option label="采购中" :value="'0,8,9,10'"></el-option>
          <el-option label="手动完结" :value="'new'"></el-option>
          <el-option label="订单完成" :value="'11,3,1'"></el-option>
          <!-- <el-option label="部分入库" :value="3"></el-option> -->
          <!-- <el-option label="已入库" :value="1"></el-option> -->
          <el-option label="已对账" :value="2"></el-option>
        </el-select>
        <el-select v-if="active === 3" @change="getpurchaseOrderList()" v-model="purchaseOrderData.approveStatus" placeholder="审核状态筛选" size="mini" filterable>
          <el-option label="进行中" :value="1"></el-option>
          <el-option label="驳回" :value="3"></el-option>
        </el-select>
        <div class="btnBox">
          <el-button v-if="btnLimit.includes('新增采购单')" class="lightDeepStyle" @click="routerJump(1, '')"><i class="el-icon-plus"></i>新增采购单</el-button>
          <el-button class="lightDeepStyle" @click="purchaseReturnExport()"><i class="iconfont icon-daoruxiaoshoudingdan"></i>导出</el-button>
        </div>
      </section>
      <article>
        <div @click="changeActive(1)" :class="active === 1 ? 'changeBox highlight' : 'changeBox'">审核完成</div>
        <div @click="changeActive(2)" :class="active === 2 ? 'changeBox highlight' : 'changeBox'">待提交</div>
        <div @click="changeActive(3)" :class="active === 3 ? 'changeBox highlight' : 'changeBox'">审核中</div>
        <div @click="changeActive(4)" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;" :class="active === 4 ? 'changeBox highlight' : 'changeBox'">作废</div>
      </article>
      <main>
        <el-table ref="tableHeight" row-key="index" :height="tableHeight" :data="purchaseOrderList" style="width: 100%; min-height: 270px;" tooltip-effect="dark" header-row-class-name="data-table-header" lazy :tree-props="{children: 'lineEntityList'}" :show-overflow-tooltip="true">
          <el-table-column key="1" show-overflow-tooltip prop="code" label="采购单编码" min-width="140px" align="center" fixed="left">
            <template slot-scope="scope">
              <div>
                <span style="cursor: pointer; color: rgba(18, 62, 131, 1);" @click="routerJump(2, scope.row.id)">{{scope.row.code}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="2" show-overflow-tooltip prop="type" label="订单类型" min-width="120px" align="center" fixed="left">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.type === 0">原材料采购</span>
                <span v-if="scope.row.type === 1">外协生产订单</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip type="index" label="需求行号" width="80px"  align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="产品名称" min-width="140px"  align="center">
            <template slot-scope="scope">
              <span>{{scope.row.productName}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="price" label="需求数量" min-width="80px"  align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.lineEntityList">-</span>
              <span v-else>{{scope.row.number}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="price" label="总价" min-width="80px"  align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.lineEntityList">{{(scope.row.totalPrice).toFixed(2)}}</span>
              <span v-else>{{(scope.row.price * scope.row.number).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="sellWarehouseNumber" label="供应商实时库存" min-width="120px"  align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.lineEntityList">-</span>
              <span v-else>{{scope.row.sellWarehouseNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="number" label="本次订购数量" min-width="100px"  align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="sellName" label="期望供应商" min-width="120px"  align="center">
            <template slot-scope="scope">
              <span>{{scope.row.sellName}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="deliveryType" label="送货方式" min-width="100px"  align="center">
            <template slot-scope="scope">
              <span>{{scope.row.deliveryType === 0 ? '自提' : '供方送货'}}</span>
            </template>
          </el-table-column> -->
          <el-table-column show-overflow-tooltip prop="proposerName" label="创建人" min-width="100px"  align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="100px"  align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.createTime">{{(scope.row.createTime).substring(0, 16)}}</span>
            </template>
          </el-table-column>
          <el-table-column key="3" show-overflow-tooltip prop="deliveryType" label="发货进度" min-width="160px"  align="center" fixed="right">
            <template slot-scope="scope">
              <el-progress :width="24" :show-text="false" type="circle" :percentage="(scope.row.deliveryNumber / scope.row.number * 100)"></el-progress>
              <span style="margin: 0 10px;">{{(scope.row.deliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
              <span>{{scope.row.deliveryNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column key="4" show-overflow-tooltip prop="deliveryType" label="收货进度" min-width="160px"  align="center" fixed="right">
            <template slot-scope="scope">
              <el-progress color="#20D4CA" :width="24" :show-text="false" type="circle" :percentage="(scope.row.takeDeliveryNumber / scope.row.number * 100)"></el-progress>
              <span style="margin: 0 10px;">{{(scope.row.takeDeliveryNumber / scope.row.number * 100).toFixed(2).replace('.00', '')}}%</span>
              <span>{{scope.row.takeDeliveryNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column key="5" show-overflow-tooltip prop="state" label="订单状态" min-width="80px"  align="center" fixed="right">
            <template slot-scope="scope">
              <div>
                <!-- 0待发货 8部分发货 9待收货 10部分收货 -->
                <span class="stateBox3" v-if="scope.row.state === 0">采购中</span>
                <span class="stateBox3" v-if="scope.row.state === 8">采购中</span>
                <span class="stateBox3" v-if="scope.row.state === 9">采购中</span>
                <span class="stateBox3" v-if="scope.row.state === 10">采购中</span>
                <span class="stateBox1" v-if="scope.row.state === 7">待确认</span>
                <span class="stateBox2" v-if="scope.row.state === 1">{{scope.row.stateType == 0 ? '订单完成' : '手动完结'}}</span>
                <span class="stateBox2" v-if="scope.row.state === 2">已对账</span>
                <span class="stateBox2" v-if="scope.row.state === 3">{{scope.row.stateType == 0 ? '订单完成' : '手动完结'}}</span>
                <span class="stateBox2" v-if="scope.row.state === 11">{{scope.row.stateType == 0 ? '订单完成' : '手动完结'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="6" show-overflow-tooltip prop="source" label="来源" min-width="80px"  align="center" fixed="right">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.source == 1 ? '计划' : scope.row.source == 2 ? '询价' : scope.row.source == 3 ? '竞价' : '-'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="7" show-overflow-tooltip label="操作" min-width="80px"  align="center" fixed="right">
            <template slot-scope="scope">
              <el-dropdown v-if="scope.row.lineEntityList" @command="chooseCommand($event, scope.row)">
                <span>
                  <i style="transform: rotate(90deg)" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdownUl">
                  <!-- 审批状态 0 制单 1进行中 2 审批完成 3驳回 4 手动结束 -->
                  <el-dropdown-item command="1" v-if="(scope.row.approveStatus === 0 || scope.row.approveStatus === 1 || scope.row.approveStatus === 2) && (scope.row.state === 4 || scope.row.state === 5 || scope.row.state === 7) && btnLimit.includes('编辑采购单')">
                    <i class="el-icon-edit-outline"></i><span>编辑</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="2" v-if="scope.row.approveStatus === 3">
                    <i class="el-icon-edit-outline"></i><span>变更</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="6" v-if="active === 2 && btnLimit.includes('提交采购单')">
                    <i class="iconfont icon-tijiaoshenhe"></i><span>提交</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="3" v-if="scope.row.approveStatus !== 1 && (scope.row.state === 7 || scope.row.state === 6) && btnLimit.includes('删除采购单')">
                    <i class="el-icon-delete"></i><span>删除</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="4" v-if="scope.row.approveStatus === 1">
                    <i class="el-icon-switch-button"></i><span>结束</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="5" v-if="scope.row.approveStatus === 2 && (scope.row.state === 3 || scope.row.state === 8) && btnLimit.includes('完结采购单')">
                    <i class="el-icon-switch-button"></i><span>完结</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <div slot="empty" class="emptyBg">
            <img src="../../assets/img/notData.png" style="width:36%; margin-top: 0;">
            <span style="display: block; margin-top: -14%">暂无数据</span>
          </div>
        </el-table>
        <el-pagination
          style="margin: 10px 0"
          layout="prev, pager, next"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          @size-change="handlesizeChange"
          :page-sizes="[50, 100, 500]"
          :page-size="purchaseOrderData.size"
          :total="total">
        </el-pagination>
      </main>
    </div>
  </div>
</template>
<script>
// echarts图
import * as echarts from "echarts";
import { purchaseOrderListApi, delPurchaseOrderApi, purchaseOrderEndApi, overPurchaseOrderApi, submitPurchaseOrderApi, cgdExportApi, purchasePlanListApi, getPlanOrderListApi, indexTitleApi, getOrderDayCountApi } from '@/api'
function formatToYYYYMMDDHHMMSS(date) {
  const yyyy = date.getFullYear();
  const MM = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const HH = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');

  return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
}

function getCustomDateRange(type) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  let createTimeBegin = '';
  let createTimeEnd = '';

  switch (type) {
    case 1: // 当前时间及当前时间以前的值
      createTimeBegin = ''; // 不传或者传空字符串表示不限制开始时间
      createTimeEnd = formatToYYYYMMDDHHMMSS(currentDate); // 当前时间
      break;
    case 2: // 一周内的值
      const oneWeekAgo = new Date(currentDate);
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      createTimeBegin = formatToYYYYMMDDHHMMSS(oneWeekAgo); // 一周前
      createTimeEnd = formatToYYYYMMDDHHMMSS(currentDate); // 当前时间
      break;
    case 3: // 当月的值
      createTimeBegin = formatToYYYYMMDDHHMMSS(new Date(currentYear, currentMonth, 1)); // 本月初
      createTimeEnd = formatToYYYYMMDDHHMMSS(currentDate); // 当前时间
      break;
    case 4: // 下个月的值
      const nextMonth = new Date(currentYear, currentMonth + 1, 1);
      createTimeBegin = formatToYYYYMMDDHHMMSS(nextMonth); // 下个月初
      createTimeEnd = formatToYYYYMMDDHHMMSS(new Date(currentYear, currentMonth + 2, 0)); // 下个月末
      break;
    case 5: // 下下个月的值
      const nextNextMonth = new Date(currentYear, currentMonth + 2, 1);
      createTimeBegin = formatToYYYYMMDDHHMMSS(nextNextMonth); // 下下个月初
      createTimeEnd = formatToYYYYMMDDHHMMSS(new Date(currentYear, currentMonth + 3, 0)); // 下下个月末
      break;
    default:
      // 默认情况
      createTimeBegin = '';
      createTimeEnd = '';
  }

  return {
    createTimeBegin,
    createTimeEnd
  };
}
export default {
  name: 'purchaseOrder',
  data() {
    return {
      // 采购单每日新增图表数据
      dayCount: [],
      // 首页标题数据
      indexTitleData: '',
      // 选中的物料列表
      chooseMatterList: [],
      // 打开转订单弹框
      turnOrderDialog: false,
      // 计划单对应的物料列表
      matterList: [],
      // 计划单对应物料传参
      matterData: {
        page: 1,
        size: 100,
        productName: '',
        selectAll: '',
        planIds: '',
        approveStatus: ''
      },
      // 选择计划
      choosePlan: 1,
      // 控制是否全部勾选
      chooseAllList: false,
      purchasePlanPage: '',
      // 采购计划列表
      purchasePlanList: [],
      // 传参参数
      purchasePlanData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        createTimeBegin: '',
        createTimeEnd: '',
        buyTimeBegin: '',
        buyTimeEnd: '',
        approveStatus: '',
        proposerId: '',
        ifAwait: ''
      },
      // ----------------------------------------------
      // 选中的标签
      active: 1,
      // 列表动态高度
      tableHeight: '',
      // 采购单列表
      purchaseOrderList: [],
      // 默认显示页码
      currentPage: 1,
      // 默认每页显示条数
      pageSize: 50,
      // 总页数
      totalNum: 0,
      // 总条数
      total: 0,
      // 传参参数
      purchaseOrderData: {
        // 当前页
        page: 1,
        // 当前页条数
        size: 10,
        code: '',
        productName: '',
        approveTimeBegin: '',
        approveTimeEnd: '',
        approveStatus: 2,
        state: '',
        stateType: '',
        sellId: '',
        ifAwait: '',
        companyType: 1
      },
      chooseTime: '',
      // 按钮权限列表
      btnLimit: []
    }
  },
  created() {
  },
  mounted() {
    // 获取按钮权限
    this.btnLimit = window.sessionStorage.getItem('btnLimit')
    if (this.$route.query.indexData && this.$route.query.indexData == 'today') {
      this.purchaseOrderData.approveTimeBegin = new Date().toISOString().split('T')[0] + ' 00:00:00'
      this.purchaseOrderData.approveTimeEnd = new Date().toISOString().split('T')[0] + ' 23:59:59'
      this.chooseTime = [new Date().toISOString().split('T')[0] + ' 00:00:00', new Date().toISOString().split('T')[0] + ' 23:59:59']
    }
    if (this.$route.query.indexState && this.$route.query.indexState == '0,8,9,10') {
      this.purchaseOrderData.state = '0,8,9,10'
    }
    if (this.$route.query.ifAwait !== undefined) {
      this.purchaseOrderData.ifAwait = this.$route.query.ifAwait
    }
    if (this.$route.query.state !== undefined) {
      this.purchaseOrderData.state = this.$route.query.state - 0
    }
    this.getpurchaseOrderList()
    this.switchMyBox(1)
    // this.getPurchasePlanList()
    this.indexTitle().then(() => {
      this.getChartsData().then(() => {
        this.showCharts()
      })
    })
    // 动态获取表格高度
    const that = this
    // 根据浏览器高度设置初始高度
    this.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 382
    // 监听浏览器高度变化，改变表格高度
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - this.$refs.tableHeight.$el.offsetTop - 382
      })()
    }
  },
  methods: {
    // 获取图表数据
    async getChartsData() {
      const { data: res } = await getOrderDayCountApi()
      if (res.code !== 0) return this.$message.error(res.msg)
      this.dayCount = res.data.list
    },
    // 图表
    showCharts() {
      this.myChart = echarts.init(document.getElementById("mains"));
      this.myChart.setOption({
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {   // 隐藏标题
          show: false,
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
            axisLine: {  // 隐藏Y轴轴线
              show: false,
            },
            axisLabel: {  // 隐藏Y轴标签
              show: false,
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {  // 隐藏Y轴轴线
              show: false,
            },
            axisLabel: {  // 隐藏Y轴标签
              show: false,
            },
            splitLine: { show: false }
          }
        ],
        series: [
          {
            name: 'Line 3',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(55, 162, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(116, 21, 219)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: this.dayCount
          }
        ]
      })
    },
    // 获取工作台头部数据
    async indexTitle() {
      const { data: res } = await indexTitleApi()
      if (res.code !== 0) return this.message.error(res.msg)
      this.indexTitleData = res.data.list
    },
    // -------------
    // 切换采购计划时间
    switchMyBox(val) {
      this.choosePlan = val
      if (val !== 6) {
        const dateRange = getCustomDateRange(val)
        this.purchasePlanData.buyTimeBegin = dateRange.createTimeBegin
        this.purchasePlanData.buyTimeEnd = dateRange.createTimeEnd
        this.getPurchasePlanList()
      } else if (val === 6) {
        this.$router.push({ path: "/purchasePlanManage" })
      }
    },
    // 全选(搜索物料专用)
    allMatterChoose(selection) {
      this.chooseMatterList = selection
    },
    // 勾选其中的一个对账单明细列表
    handleChoosePartList(selection) {
      if (selection === true) {
        // 这里可以将勾选的值存储起来(暂时没后续)
        let isLast = true
        // 判断当前勾选的是否是最后一个待勾选项
        this.purchasePlanList.forEach((item, index) => {
          if (item.choose === false) {
            isLast = false
          }
        })
        if (isLast === true) {
          // 让全选框勾选上
          this.chooseAllList = true
        }
      } else {
        this.chooseAllList = false
      }
    },
    // 确认产品选择
    confirmChoose() {
      if (this.chooseMatterList.length === 0) return this.$message.error('请选择需要转订单的产品')
      this.$router.push({ path: "/addPurchaseOrder", query: { result: JSON.stringify(this.chooseMatterList) }})
    },
    // 手动勾选的参数(物料搜索专用)
    handleMatterSelectionChange(selection, row) {
      this.chooseMatterList = selection
    },
    // 点击行触发勾选事件
    handleMatterSelectionChangeRow(selection) {
      // 判断勾选数组有没有值
      if (this.chooseMatterList.length !== 0) {
        // 判断勾选的数组中有没有当前勾选的值
        // 如果有就给它去掉
        let controlItem = true
        this.chooseMatterList.forEach((item, index) => {
          if (item.id === selection.id) {
            controlItem = false
            this.chooseMatterList.splice(index, 1)
            return false
          }
        })
        if (controlItem === true) {
          this.chooseMatterList.push(selection)
        }
      } else {
        this.chooseMatterList.push(selection)
      }
      this.$refs.matterSearchRef.toggleRowSelection(selection)
    },
    // 获取计划单对应的物料列表
    async getPlanOrderList() {
      const { data: res } = await getPlanOrderListApi(this.matterData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.matterList = res.data.data.list
    },
    // 打开转订单弹框
    async openTurnOrder() {
      // 获取勾选的采购计划单
      let chooseArray = []
      let confirmApproveStatus = true
      this.purchasePlanList.forEach((item, index) => {
        if (item.choose === true && item.approveStatus === 2) {
          chooseArray.push(item.id)
        } else if (item.choose === true && item.approveStatus !== 2) {
          confirmApproveStatus = false
        }
      })
      if (confirmApproveStatus === false) return this.$message.error('只允许勾选审批完成的计划单!')
      if (chooseArray.length === 0) return this.$message.error('请勾选采购计划单!')
      this.matterData.planIds = chooseArray.join(',')
      this.getPlanOrderList()
      this.turnOrderDialog = true
    },
    // 勾选采购计划单全选时触发
    handleChooseAllList(selection) {
      if (this.purchasePlanList.length !== 0) {
        this.purchasePlanList.forEach((item, index) => {
          item.choose = selection
        })
      }
    },
    // 获取采购计划列表
    async getPurchasePlanList(pushOrder) {
      const { data: res } = await purchasePlanListApi(this.purchasePlanData)
      let newPurchasePlanList = res.data.data.list
      // 给数据添加唯一值index,用于展开
      if (newPurchasePlanList.length !== 0) {
        newPurchasePlanList.forEach((item, index) => {
          item.choose = false
          item.index = index + ''
          if (item.lineEntityList.length !== 0) {
            item.lineEntityList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      // 展会页面总页数
      this.purchasePlanPage = res.data.data.totalPage
      // 获取表单数据
      if (pushOrder === true) {
        res.data.data.list.forEach(item => {
          item.choose = false
          this.purchasePlanList.push(item)
        })
      } else {
        let newPurchasePlanList = res.data.data.list
        // 给数据添加唯一值index,用于展开
        if (newPurchasePlanList.length !== 0) {
          newPurchasePlanList.forEach((item, index) => {
            item.choose = false
          })
        }
        this.purchasePlanList = newPurchasePlanList
      }
    },
    // 采购计划滚动
    handleScroll() {
      const tableBody = this.$refs.tableHeight2.$el.querySelector('.el-table__body-wrapper');
      const scrollTop = tableBody.scrollTop;
      const scrollHeight = tableBody.scrollHeight;
      const clientHeight = tableBody.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (this.purchasePlanData.page < this.purchasePlanPage) {
          this.purchasePlanData.page = this.purchasePlanData.page + 1
          this.getPurchasePlanList(true)
        }
      }
    },
    // ---------------------------------------
    // 完结
    overPurchaseOrder(id) {
      this.$confirm('是否确认完结该采购单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await overPurchaseOrderApi({ id: id, state: 11, stateType: 1 })
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('完结成功!')
        this.getpurchaseOrderList()
      })
    },
    // 选择下拉
    chooseCommand(command, result) {
      if (command === '1') {
        this.routerJump(3, result.id)
      } else if (command === '2') {
        this.routerJump(3, result.id)
      } else if (command === '3') {
        this.delPurchaseOrder(result.id)
      } else if (command === '4') {
        this.endPurchaseOrder(result.id)
      } else if (command === '5') {
        this.overPurchaseOrder(result.id)
      } else {
        this.$confirm('是否确认提交该采购单', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(async () => {
          this.submitPurchaseOrder(result.id)
        })
      }
    },
    // 提交计划单
    async submitPurchaseOrder(id) {
      const { data: res } = await submitPurchaseOrderApi({ id: id, enquiryId: '' })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('提交成功!')
      this.getpurchaseOrderList()
    },
    // 切换标签页
    changeActive(val) {
      this.active = val
      if (val === 0) {
        this.purchaseOrderData.state = ''
        this.purchaseOrderData.approveStatus = ''
      } else if (val === 1) {
        // 审核完成的状态(在审核完成时搜索状态改state  0待发货 1已入库 2已对账 3部分入库 7待确认 8 部分发货 9待收货 10部分收货 11已收货)
        this.purchaseOrderData.state = ''
        this.purchaseOrderData.approveStatus = 2
      } else if (val === 2) {
        // 待提交的状态
        this.purchaseOrderData.state = 4
        this.purchaseOrderData.approveStatus = ''
      } else if (val === 3) {
        // 审核中的状态(在审核中时搜索状态改approveStatus 1进行中 3驳回)
        this.purchaseOrderData.state = 5
        this.purchaseOrderData.approveStatus = ''
      } else if (val === 4) {
        // 作废的状态
        this.purchaseOrderData.state = 6
        this.purchaseOrderData.approveStatus = ''
      }
      this.getpurchaseOrderList()
      // 解决样式错乱的问题
      this.$nextTick(() => {
        this.$refs.tableHeight.doLayout()
      })
    },
    // 点击切换时间表
    getTimess(result) {
      if (result === null) {
        this.purchaseOrderData.approveTimeBegin = ''
        this.purchaseOrderData.approveTimeEnd = ''
      } else {
        result.forEach((value, index) => {
          let timers = new Date(value)
          let getYear = timers.getFullYear()
          let nowMonth = timers.getMonth() + 1
          let getDate = timers.getDate()
          // 判断月份补0
          if (nowMonth < 10) { nowMonth = '0' + nowMonth }
          // 判断日期补0
          if (getDate < 10) { getDate = '0' + getDate }
          if (index === 0) {
            this.purchaseOrderData.approveTimeBegin = getYear + '-' + nowMonth + '-' + getDate + ' 00:00:00'
          } else {
            this.purchaseOrderData.approveTimeEnd = getYear + '-' + nowMonth + '-' + getDate + ' 23:59:59'
          }
        })
      }
      this.getpurchaseOrderList()
    },
    // 获取采购单列表
    async getpurchaseOrderList(reset) {
      if (reset !== 'reset') {
        this.purchaseOrderData.page = 1
      }
      let newPurchaseOrderData = JSON.parse(JSON.stringify(this.purchaseOrderData))
      if (newPurchaseOrderData.state === '') {
        newPurchaseOrderData.state = '7,0,8,9,10,11,3,1,2'
      }
      if (newPurchaseOrderData.state === 'new') {
        newPurchaseOrderData.state = '11,3,1'
        newPurchaseOrderData.stateType = 1
      } else {
        newPurchaseOrderData.stateType = 0
      }
      const { data: res } = await purchaseOrderListApi(newPurchaseOrderData)
      // 获取表单数据
      let newPurchaseOrderList = res.data.data.list
      // 展示当前页面
      this.currentPage = res.data.data.currPage
      // 展会页面总页数
      this.totalNum = res.data.data.totalPage
      // 展示总条数
      this.total = res.data.data.totalCount
      // 给数据添加唯一值index,用于展开
      if (newPurchaseOrderList.length !== 0) {
        newPurchaseOrderList.forEach((item, index) => {
          item.index = index + ''
          if (item.lineEntityList.length !== 0) {
            item.lineEntityList.forEach((val, i) => {
              val.index = index + '' + i
            })
          }
        })
      }
      this.purchaseOrderList = newPurchaseOrderList
    },
    // 触发每页显示条数
    handlesizeChange(val) {
      this.purchaseOrderData.size = val
      this.purchaseOrderData.page = 1
      this.getpurchaseOrderList('reset')
    },
    // 当前页数据动态改变
    handleCurrentChange(val) {
      this.purchaseOrderData.page = val
      this.currentPage = val
      this.getpurchaseOrderList('reset')
    },
    // -----------------------------------------------------------------
    // 删除
    delPurchaseOrder(id) {
      this.$confirm('是否确认删除该采购单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        console.log('????', id)
        const { data: res } = await delPurchaseOrderApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('删除成功!')
        this.getpurchaseOrderList()
      })
    },
    // 结束
    endPurchaseOrder(id) {
      this.$confirm('是否手动结束该采购单审批流', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { data: res } = await purchaseOrderEndApi(id)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('结束成功!')
        this.getpurchaseOrderList()
      })
    },
    // 路由跳转
    routerJump(val, result) {
      if (val === 1) {
        this.$router.push({ path: '/addPurchaseOrder' })
      } else if (val === 2) {
        this.$router.push({ path: '/purchaseOrderDetail', query: { result: JSON.stringify(result) } })
      } else if (val === 3) {
        this.$router.push({ path: '/editPurchaseOrder', query: { result: JSON.stringify(result) } })
      } else if (val === 4) {
        this.$router.push({ path: "/purchasePlanDetail", query: { result: JSON.stringify(result) }})
      }
    },
    async purchaseReturnExport() {
      const { data: res } = await cgdExportApi(this.purchaseOrderData)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.getpurchaseOrderList()
    }
  }
}
</script>
<style scoped lang='less'>
.purchasePlan {
  margin: 20px;
  h5 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: rgba(0,0,0,0.8);
    line-height: 24px;
  }
  nav {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .navLeft {
      padding: 20px;
      width: 240px;
      height: 254px;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      p {
        position: relative;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.80);
          font-weight: bold;
        }
        i {
          position: absolute;
          top: 20px;
          left: 0;
          font-size: 28px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.80);
        }
      }
      #mains {
        width: 100%;
        height: 195px;
        box-sizing: border-box;
      }
    }
    .navRight {
      padding: 20px;
      width: calc(100% - 260px);
      height: 254px;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      .navRightHeader {
        display: flex;
        justify-content: space-between;
        span {
          margin-left: 20px;
          padding: 2px 10px;
          display: inline-block;
          background-color: rgba(248, 249, 250, 1);
          border-radius: 12px;
          border: 1px solid rgba(248, 249, 250, 1);
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.40);
          line-height: 18px;
        }
        .chooseMy {
          background-color: #fff;
          border: 1px solid rgba(18, 62, 131, 1);
          font-size: 12px;
          font-weight: bold;
          color: rgba(18, 62, 131, 1);
          line-height: 16px;
        }
      }
      .navRightContent {
        width: 100%;
        height: 195px;
        /deep/ .el-table .el-table__cell {
          padding: 6px 0;
        }
        .controlBox {
          margin-left: 50%;
          transform: translate(-50%);
          display: block;
          width: 64px;
          height: 22px;
          border-radius: 4px 4px 4px 4px;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.15);
          cursor: pointer;
          color: rgba(0, 0, 0, 0.80);
          font-size: 12px;
          font-weight: bold;
          i {
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }
}
/deep/ .chooseOrderDialog {
  .el-dialog {
    width: 680px;
    height: 474px;
    border-radius: 16px;
    .el-dialog__header {
      padding: 20px 20px 0;
      vertical-align: top;
      border-radius: 16px;
      .el-dialog__headerbtn {
        top: 20px;
      }
    }
    .el-dialog__body {
      padding-bottom: 0;
      height: 354px!important;
      width: 100%;
      .sectionInp {
        margin: 20px 20px 20px 0;
        height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        /deep/.el-input {
          width: 160px !important;
          font-size: 12px;
          .el-input__inner {
            font-size: 12px;
          }
        }
      }
      .el-table {
        margin-top: 5px;
        height: 325px;
        td {
          padding: 0;
          .cell {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
    .el-dialog__footer {
      height: 72px;
      // border-top: 0.3px solid #EBEEF5;
      border-top: 0;
      .dialog-footer {
        width: 100%;
        height: 72px;
        .el-button {
          position: absolute;
          right: 70px;
          margin-top: 20px;
          padding: 0;
          height: 32px;
          width: 60px;
          border-radius: 4px;
          line-height: 0;
        }
        .cancleBtn {
          right: 0;
        }
      }
    }
  }
}
.purchaseOrder {
  margin: 20px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 6px;
  padding: 0 3px;
  width: calc(100% - 40px);
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;
  section {
    margin: 20px 20px 0;
    position: relative;
    display: flex;
    justify-content: flex-start;
    .sectionInp {
      margin: 0 20px 0 0;
      height: 32px;
      width: 120px;
      display: flex;
      align-items: center;
      /deep/.el-input {
        width: 160px !important;
        font-size: 12px;
        .el-input__inner {
          font-size: 12px;
        }
      }
    }
    .block {
      margin-right: 20px;
    }
    .btnBox {
      position: absolute;
      right: 0;
      width: 200px;
      .el-button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
  article {
    margin: 20px 20px 10px;
    display: flex;
    .changeBox {
      margin-left: -1px;
      height: 32px;
      width: 68px;
      background-color: rgba(248, 249, 250, 1);
      cursor: pointer;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.60);
    }
    .highlight {
      background-color: rgba(18, 62, 131, 1);
      border-color: rgba(18, 62, 131, 1);
      color: #fff;
    }
  }
  main {
    margin: 0 20px;
    /deep/ .el-table .cell.el-tooltip {
      display: flex;
      justify-content: center;
      .table-fixed {
        /deep/.el-table__fixed-right {
            height: 100% !important
        }
        /deep/.el-table__fixed {
            height:100% !important
        }
      }
    }
    .stateBox1, .stateBox2, .stateBox3 {
      display: block;
      width: 64px;
      height: 22px;
      background: rgba(239, 188, 96, 0.05);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(239, 188, 96, 0.2);
      font-size: 12px;
      color: rgba(239, 188, 96, 1);
    }
    .stateBox2 {
      background-color: rgba(70, 147, 235, 0.05);
      border-color: rgba(70, 147, 235, 0.20);
      color: rgba(70, 147, 235, 1);
    }
    .stateBox3 {
      background: rgba(59,212,144,0.05);
      border: 1px solid rgba(59,212,144,0.2);
      color: rgba(59, 212, 144, 1);
    }
  }
}
</style>
